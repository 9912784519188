import styles from "../HeaderMyProfile.module.scss";
import { useSetURL } from "../../../../header/hooks";
import { useTranslation } from "react-i18next";

const UserLoaded = () => {

    const setURL = useSetURL();
    const { t } = useTranslation();
    
    return (
        <>
         <a
            className={ styles.dropdownItem }
            href={ setURL("user-dashboard/", true) }
          >
            <span>{t("header.myprofile")}</span>
          </a>
          <div className={ styles.dropdownLine } />
          <a
            className={ styles.dropdownItem }
            href={ setURL("user-dashboard/?opt=2", true) }
          >
            <span>{t("header.security")}</span>
          </a>
          <div className={ styles.dropdownLine } />

          <a
            className={ styles.dropdownItem }
            href={ setURL("user-dashboard/?opt=5", true) }
          >
            <span>{t("header.myremittances")}</span>
          </a>
          <div className={ styles.dropdownLine } />
          <a
            className={ styles.dropdownItem }
            href={ setURL("user-dashboard/?opt=6", true) }
          >
            <span>{t("header.settings")}</span>
          </a>   
        </>
    )
}

export default UserLoaded
