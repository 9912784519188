import React, { useState } from 'react';
import styles from "./Input.module.scss";
import ErrorIcon from '../../assets/images/error-icon.svg';
import InputErrorIcon from '../../assets/images/input/error-icon-warning.svg';

const Input = React.forwardRef((props, ref) => {

  const { id, type, placeholder, primary = true, hasError, onBlur, label, errorLabel, hasErrorIcon, onKeyDown, onKeyPress, onChange, disabled, value, name, placeholderSmall = false, className, pattern, maxlength, max, min, style, warningLabel = false, hasErrorInputIcon = true, noPadding = false, autoComplete = "nope", dataPrivate = false } = props;

  return (

    <div className={`${styles["input-component"]} ${className}`}>
      {
        label &&
        <label className={primary ? styles["label-primary"] : styles["label-secondary"]}>{label}</label>
      }
      {disabled
        ? <input id={id} readOnly className={!noPadding ? styles["input-disabled"] : styles["input-disabled"]} placeholder={placeholder} value={value} name={name} autoComplete={autoComplete}></input>
        :
        <div className={styles["input-container"]}>
          {dataPrivate
            ?
            <>
              <input data-private ref={ref} onKeyPress={onKeyPress} onBlur={onBlur} type={type} max={max} min={min} pattern={pattern} maxLength={maxlength} id={id} className={`${(placeholderSmall ? styles["input-placeholdersmall"] : styles["input"])} ${className}`} placeholder={placeholder} onKeyDown={onKeyDown} onChange={onChange} value={value} name={name} autoComplete={autoComplete}></input>
              {
                hasError && hasErrorInputIcon &&
                <img alt='input-error-icon' src={InputErrorIcon} />
              }
            </>
            :
            <>
              <input ref={ref} onKeyPress={onKeyPress} onBlur={onBlur} type={type} max={max} min={min} pattern={pattern} maxLength={maxlength} id={id} className={`${(placeholderSmall ? styles["input-placeholdersmall"] : styles["input"])} ${className}`} placeholder={placeholder} onKeyDown={onKeyDown} onChange={onChange} value={value} name={name} autoComplete={autoComplete}></input>
              {
                hasError && hasErrorInputIcon &&
                <img alt='input-error-icon' src={InputErrorIcon} />
              }
            </>
          }
        </div>
      }
      {
        warningLabel ?
          hasError &&
          <div className={styles["warning-label"]}>
            {
              hasErrorIcon &&
              <img alt='error-icon' src={ErrorIcon} />
            }
            <p>{errorLabel.split('.')[0] + "."}</p>
            <p>{errorLabel.split('.')[1] + "."}</p>
          </div>
          :
          hasError &&
          <div className={styles["error-label"]}>
            {
              hasErrorIcon &&
              <img alt='error-icon' src={ErrorIcon} />
            }
            <p>{errorLabel}</p>
          </div>
      }
    </div>
  )

});

export default Input; 