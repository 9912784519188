import axios from "./axios";

export const getNotifications = async() => {
  const headers = {
    Session: "someSessionToken",
    "Content-Type": "application/json",
  };
  return axios.get("credit_checks/display-alerts", { headers });
};

export const toggleNotificationStatus = async alertID => {
  return axios.post("credit_checks/update-notification-cch", { alertID });
};
