// @modules
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, useLocation } from "react-router-dom";

export default function PrivateRoute({
  component: Component,
  ...props
}) {
  const location = useLocation();
  const [token] = useState(sessionStorage.getItem("sessionToken"));

  return (
    <Route { ...props }>
      { token ? (
        <Component />
      ) : (
        <Redirect to={ {
          pathname: "/login",
          state: { from: location },
        } } />
      ) }
    </Route>
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
};
