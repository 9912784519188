import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

// hook for displaying email validity error to the user
// in order to improve user experience, the logic is set as follows:
// email is first validated after first 'blur' event, then after each 'change' event

// assign 'onEmailBlur' and 'onEmailChange' event handlers as input element's event handlers
// for additional error message logic other than email structure, use optionalErrorPromiseOnBlur parameter

const validateEmail = email => {
  return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/.test(email);
};

const useEmailValidation = optionalErrorPromiseOnBlur => {
  const { t } = useTranslation();
  const [isEmailError, setIsEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [firstBlurOccurred, setFirstBlurOccurred] = useState(false);

  const onEmailBlur = useCallback(
    event => {
      const isValid = validateEmail(event.target.value);
      if (!firstBlurOccurred) {
        setIsEmailError(!isValid);
        setFirstBlurOccurred(true);
        setEmailErrorMessage(t("form.error.invalidEmail"));
      }
      if (optionalErrorPromiseOnBlur && isValid) {
        optionalErrorPromiseOnBlur().then(res => {
          setIsEmailError(res.isError);
          setEmailErrorMessage(res.errorMessage);
        });
      }
    },
    [optionalErrorPromiseOnBlur, firstBlurOccurred, t],
  );

  const onEmailChange = useCallback(
    event => {
      const isValid = validateEmail(event.target.value);
      if (firstBlurOccurred) {
        setIsEmailError(!isValid);
        setEmailErrorMessage(
          isValid ? undefined : t("form.error.invalidEmail"),
        );
      }
    },
    [firstBlurOccurred, t],
  );

  return { onEmailBlur,
    onEmailChange,
    isEmailError,
    emailErrorMessage };
};

export default useEmailValidation;
