// @styles
import Style from "./Step.module.scss";
import {
    faImage,
    faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines } from "@fortawesome/free-solid-svg-icons";

// @modules
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

function Step({
    label,
    checked,
    active,
    icon,
    image,
    dots,
    firstDots
}) {

    const [iconColumnWidth, setIconColumnWidth] = useState(0);

    function RenderChild(children) {
        return children;
    }

    function setLastColumnWidth() {
        let iconColumn = document.querySelector(".icon-column");

        if (!!iconColumn) {
            if (window.innerWidth >= 992) {
                setIconColumnWidth(iconColumn.clientWidth);
            } else {
                setIconColumnWidth(80);
            }
        }
    }

    useEffect(() => {
        setLastColumnWidth();
        window.addEventListener('resize', setLastColumnWidth);
    }, []);

    return (
        <>
            <div className={`${Style['step']} ${(active ? Style['step-active'] : '')}  ${(dots ? Style['main'] : 'main-no-dotted')}`}>
                <div className={`icon-column ${Style['content-row']} ${(active ? Style['icon-active-column'] : '')}`}>
                    
                    {active ? (
                        <>
                            {
                                !!image.active ?
                                    <img src={image.active} className={`${Style['icon-general']} ${Style['icon-active']}`} />
                                    :
                                    <FontAwesomeIcon className={`${Style['icon-general']} ${Style['icon-active']}`} icon={icon} />
                            }
                            <span className={`capitalize-first-letter ${Style['general-text']} ${Style['text-active']} font-inter-10-700`}>{label}</span>
                        </>
                    )
                        : <>
                            {checked ?
                                <>
                                    <FontAwesomeIcon className={`${Style['icon-general']} ${Style['icon-checked']}`} icon={faCheckCircle} />
                                    <span className={`capitalize-first-letter ${Style['general-text']} ${Style['text-checked']} font-inter-10-400`}>{label}</span>
                                </>
                                :
                                <>
                                    {
                                        !!image.default ?
                                            <img src={image.default} className={`${Style['icon-general']} ${Style['icon-unselected']}`} />
                                            :
                                            <FontAwesomeIcon className={`${Style['icon-general']} ${Style['icon-unselected']}`} icon={icon} />
                                    }
                                    <span className={`capitalize-first-letter ${Style['general-text']} ${Style['text-unselected']} font-inter-10-500`}>{label}</span>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
            {dots &&
                <>
                    <div className={`icon-column 
                                    ${Style['text-dots']} 
                                    ${(active ? Style['text-active-dots'] : Style['text-inactive-dots'])} 
                                    ${(firstDots ? Style['text-active-dots-left'] : Style['text-active-dots-right'])}`}>
                        ••••••••••••••••••••
                    </div>
                </>
            }
        </>
        
    );
}

Step.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool
};

Step.defaultProps = {
    label: '',
    checked: false,
    icon: faImage,
    dots: true,
    image: {
        default: '',
        active: ''
    }
}

export default Step;