/**
 * Parse form element to object with it values.
 * @param  {HTMLFormElement} form Form element that will be parsed
 * @return {Object} Object with values of form
 */
export const parseFormToObject = form => {
  const formData = new FormData(form);
  const formValues = Object.fromEntries(formData.entries());

  return formValues;
};

/**
* Helper to get values when form submit event happens.
* @param {Event} event - Form submit event.
* @return {Object} Form values in object(key:value).
*/
export const onSubmitHelper = event => {
  event.preventDefault();
  const values = parseFormToObject(event.target);

  return values;
};

export const isValidCreditCard = code => {
  const length = code.length;
  const parity = length % 2;

  let checkSum = 0;

  for (let i = length - 1; i >= 0; --i) {
    let digit = parseInt(code.charAt(i));

    if (i % 2 === parity)
      digit *= 2;

    if (digit > 9)
      digit -= 9;

    checkSum += digit;
  }

  return checkSum % 10 === 0;
};

export const isValidEmail = email => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
};
