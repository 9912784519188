// @modules
import React from "react";
import PropTypes from "prop-types";
import Control from "react-bootstrap/FormControl";
function InputAmount({
  error,
  placeholder,
  onChange,
  ...props
}) {
  return (
    <Control
      onChange={ onChange }
      placeholder={ placeholder }
      type="number"
      { ...props }
    />
  );
}

InputAmount.propTypes = {
  placeholder: PropTypes.any,
};

export default InputAmount;
