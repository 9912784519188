// @assets
import successImage from "../../assets/images/successModal.png";
// @styles
import "./ModalSuccess.scss";
// @components
import Modal from "../modal";
// @modules
import React from "react";
import PropTypes from "prop-types";
import Button from "../button";
import { useModal } from "../../hooks";

export default function ModalSuccess({
  visibleCloseButton = false,
  title = "",
  description = "",
  handleBtnModal = null,
  txtBtn = "",
  btnId = "",
}) {
  const {
    visible,
    toggleVisibility,
  } = useModal({ initialState: true });

  return (
    <Modal visible={ visible } visibleCloseButton={ visibleCloseButton }>
      <div className="crediframework-modal-success">
        <img alt="Success modal image" className="crediframework-modal-success__image" src={ successImage } />
        <p className="crediframework-modal-success__main-message">{ title }</p>
        <p className="crediframework-modal-success__description">{ description }</p>
        <Button className="crediframework-modal-success__button" color="orange" id={ btnId } onClick={ (handleBtnModal == null) ? toggleVisibility : handleBtnModal }>{txtBtn}</Button>
      </div>
    </Modal>
  );
}

ModalSuccess.propTypes = {
  visibleCloseButton: PropTypes.bool,
};
