import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLang } from ".";

// this hook updates url search parameters in order to track the language

const useLanguageTracking = () => {
  const [ isPathRendered, setIsPathRendered ] = useState(false)
  const { lang } = useLang();
  const history = useHistory();

  const validateSearchPath = () => {
    if (window.location.hostname === 'app.crediverso.com') {
      if (!isPathRendered) {
        const searchHistory = history.location.search;
        if (searchHistory.includes('mkt_path')){
          window.fbq('track','CompleteRegistration');
        } else {
          history.replace({ search: `?lang=${lang}` });
        }
        setIsPathRendered(true);
      }
    }
  }

  useEffect(() => {
    validateSearchPath();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, lang, isPathRendered]);
};

export default useLanguageTracking;
