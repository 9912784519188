// @modules
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLang } from "../../../hooks";
import { useSetURL } from "../../hooks";

// @components
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WorldLogo, SearchIcon } from "../../../../assets";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import AuthPanel from "./AuthPanel";
import MainLogo from "../../../main-logo/MainLogo";

// @assets
import { getWordPressBaseUrl } from "../../../../utils/urlUtils";

// @styles
import "./HeaderModal.scss";
import styles from "./HeaderModal.module.scss";

const HeaderModal = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const { switchLang } = useLang();

  const handleSearch = useCallback((event) => {
    if (event.key === "Enter") {
      const search = event.target.value;
      window.open(
        `${getWordPressBaseUrl()}?s=${search.trim()}&lang=en`,
        "_blank"
      );
    }
  }, []);

  const setURL = useSetURL();

  // useEffect(() => {
  //   const appHeight = () => {
  //     const doc = document.documentElement;
  //     doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  //   };
  //   window.addEventListener("resize", appHeight);
  //   appHeight();
  //   return () => {
  //     window.removeEventListener("resize", appHeight);
  //   };
  // }, []);

  return (
    <Modal
      className="header__modal__menu_mobile"
      onHide={handleClose}
      show={show}
    >
      <div className={styles.header}>
        <div className={styles.headerTop}>
          <MainLogo />
          <button className={styles.close} onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        {/* <AuthPanel /> */}
        <div className={styles.divider} />
      </div>

      <div className={styles.body}>
        <ul>
          <span className={styles.title}>
            {t("mobile_header.financialProducts")}
          </span>
          <li>
            <a href={setURL("credit_checks/signup", false)}>
              {t("header.checkMyCredit")}
            </a>
          </li>
          {/* <li>
            <a href={setURL("remittance", true)}>{t("header.sendMoney")}</a>
          </li> */}
          <li className="d-none">
            <a href={setURL("creditcards", true)}>
              {t("mobile_header.creditCards")}
            </a>
          </li>
          {/* <li className="hide">
            <a href={setURL("loans", true)}>{t("mobile_header.loans")}</a>
          </li> */}
        </ul>
        <ul>
          <span className={styles.title}>{t("header.resources")}</span>
          <li>
            {/* eslint-disable-next-line */}
            <a
              href={setURL("contact-us", true)}
            >
              {t("header.askAnExpert")}
            </a>
          </li>
          <li>
            <a href={setURL("blog", true, true)}>{t("header.blog")}</a>
          </li>
          <li>
            <a href={setURL("faqs", true)}>{t("header.howItWorks")}</a>
          </li>
        </ul>
        <div className={styles.search}>
          <SearchIcon className={styles.searchIcon} />
          <input
            onKeyPress={handleSearch}
            placeholder={t("mobile_header.startsearch")}
            type="text"
          />
        </div>
        <button className={styles.lang} onClick={switchLang}>
          <img alt="USA Flag" src={WorldLogo} />
          {t("header.lang")}
        </button>
      </div>
    </Modal>
  );
};

export default HeaderModal;
