import React from "react";
import { IconCircleArrow } from "../assets";
import style from "./Notification.module.scss";

function NotificationHighCreditUsage() {
  return (
    <div style={ {
      display: "flex",
      marginTop: "3rem",
      alignItems: "center",
      flexDirection: "column",

    } }>
      <div style={ {
        height: "6rem",
      } }>
        <IconCircleArrow />
      </div>
      <h6 className={ style.notificationContentText }>Your credit Usage: 77%</h6>
      <h6 style={ {
      } }>Your credit Usage: 77%</h6>
      <h6>Your Credit Limit: $2000</h6>
      <h6>Your Current Balance: $1,540 </h6>
      <h6>Balance Date: Aug 12, 21</h6>
    </div>
  );
}

export { NotificationHighCreditUsage };
