// @modules
import React from "react";
import Control from "react-bootstrap/FormControl";
import PropTypes from "prop-types";

function InputPassword({
  placeholder,
  ...props
}) {
  return (
    <Control
      placeholder={ placeholder }
      type="password"
      { ...props }
    />
  );
}

InputPassword.propTypes = {
  placeholder: PropTypes.string,
};

export default InputPassword;
