// @styles
import "./Card.scss";
// @components
// @modules
import React from "react";
import PropTypes from "prop-types";
// import { useCard } from "../../hooks";

function Card({ children, mobile, spacing, className }) {
  return (
    <div className={`crediframework-card ${mobile  && "mobile"} ${className}`}>
      <div className={`${!spacing ? `card-container` : 'card-container-spacing-small'}`}>
         {children}
      </div>
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.any,
  mobile: PropTypes.bool,
};

export default Card;
