import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCrediverso } from "../../../provider";
import styles from "./BottomContent.module.scss";
import MainLogo from "../../main-logo/MainLogo";

const BottomContent = () => {
  const { t } = useTranslation();
  const { computeURL } = useCrediverso();

  const baseUrl = useMemo(() => {
    const hostname = window.location.hostname;
    switch (hostname) {
      case "uat.raas.crediverso.com":
        return "https://uat.raas.crediverso.com/";
      case "app.crediverso.com":
        return "https://app.crediverso.com/";
      default:
        return "https://dev.raas.crediverso.com/";
    }
  }, []);

  const setURL = useCallback(
    (path, isWordPress) => {
      if (!isWordPress)
        return `${baseUrl}${path}`;
      return computeURL(path, isWordPress);
    },
    [baseUrl, computeURL],
  );

  return (
    <div className={ styles.bottomcontent }>
      <div className={ styles.headers }>
        <MainLogo />
        <ul>
          <li>
            <a className={ styles.terms } href={ setURL("privacy", true) }>
              {t("footer.bottom.privacypolicy")}
            </a>
          </li>
          <li>
            <a className={ styles.terms } href={ setURL("terms", true) }>
              {t("footer.bottom.terms")}
            </a>
          </li>
          <li>
            <a className={ styles.terms } href={ setURL("sitemap", true) }>
              {t("footer.bottom.sitemap")}
            </a>
          </li>
        </ul>
      </div>
      <div className={ styles.disclosure }>
        <p>{t("footer.bottom.advertiser")}</p>
        <p>{t("footer.bottom.disclaimer")}</p>
        <p>{t("footer.bottom.editorial")}</p>
      </div>
    </div>
  );
};

export default BottomContent;
