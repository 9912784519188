import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCrediverso } from "../../../provider";
import ContactUs from "../contact-us/ContactUs";
import styles from "./FooterDesktop.module.scss";

const FooterDesktop = () => {
  const { t } = useTranslation();
  const { computeURL } = useCrediverso();

  const baseUrl = useMemo(() => {
    const hostname = window.location.hostname;
    switch (hostname) {
      case "uat.raas.crediverso.com":
        return "https://uat.raas.crediverso.com/";
      case "app.crediverso.com":
        return "https://app.crediverso.com/";
      default:
        return "https://dev.raas.crediverso.com/";
    }
  }, []);

  const setURL = useCallback(
    (path, isWordPress, newHome) => {
      if (!isWordPress) return `${baseUrl}${path}`;
      return computeURL(path, isWordPress, newHome);
    },
    [baseUrl, computeURL]
  );

  return (
    <div className={styles.upperContent}>
      <ul className={styles.colInfo}>
        <p className={styles.title}>{t("footer.upper.products.title")}</p>
        {/* <li className={styles.item}>
          <a className={styles.link} href={setURL("remittance", true)}>
            {t("footer.upper.products.sendmoney")}
          </a>
        </li> */}
        <li className={styles.item}>
          <a
            className={styles.link}
            href={setURL(
              localStorage.getItem("userToken")
                ? "credit_checks/dashboard"
                : "credit_checks",
              false
            )}
          >
            {t("footer.upper.products.creditchecks")}
          </a>
        </li>
        <li className={`${styles.item} d-none`}>
          <a className={styles.link} href={setURL("creditcards", true)}>
            {t("footer.upper.products.creditcards")}
          </a>
        </li>
        {/* <li className={`${styles.item} d-none`}>
          <a className={styles.link} href={setURL("loans", true)}>
            {t("footer.upper.products.loans")}
          </a>
        </li> */}
      </ul>

      <ul className={styles.colInfo}>
        <p className={styles.title}>{t("footer.upper.community.title")}</p>
        {/* <li className={styles.item}>
          <a className={styles.link}
          href={setURL("", true)}
          >
            {t("footer.upper.company.newsroom")}
          </a>
        </li> */}
        <li className={styles.item}>
          <a className={styles.link} href={setURL("blog", true, true)}>
            {t("footer.upper.community.blog")}
          </a>
        </li>
      </ul>
      <ul className={styles.colInfo}>
        <p className={styles.title}>{t("footer.upper.company.title")}</p>
        <li className={styles.item}>
          <a className={styles.link} href={setURL("about-us", true)}>
            {t("footer.upper.company.about")}
          </a>
        </li>
        <li className={styles.item}>
          <a className={styles.link} href={setURL("faqs", true)}>
            {t("footer.upper.company.howitworks")}
          </a>
        </li>
        <li className={styles.item}>
          <a className={styles.link} href={setURL("product-update", true)}>
            {t("footer.upper.company.product")}
          </a>
        </li>
      </ul>
      <div className={styles.colInfo}>
        <ContactUs />
      </div>
    </div>
  );
};

export default FooterDesktop;
