import styles from "../HeaderMyProfile.module.scss";
import { useSetURL } from "../../../../header/hooks";
import { useTranslation } from "react-i18next";

const LoadingUser = () => {

    const setURL = useSetURL();
    const { t } = useTranslation();

    return (
        <>
          <a
            className={ styles.dropdownItemDisabled }
            href={ setURL("user-dashboard/", true) }
          >
            <span>{t("header.myprofileloading")}</span>
          </a>
          <div className={ styles.dropdownLine } />
          <a
            className={ styles.dropdownItemDisabled }
            href={ setURL("user-dashboard/?opt=2", true) }
          >
            <span>{t("header.security")}</span>
          </a>
          <div className={ styles.dropdownLine } />

          <a
            className={ styles.dropdownItemDisabled }
            href={ setURL("user-dashboard/?opt=5", true) }
          >
            <span>{t("header.myremittances")}</span>
          </a>
          <div className={ styles.dropdownLine } />
          <a
            className={ styles.dropdownItemDisabled }
            href={ setURL("user-dashboard/?opt=6", true) }
          >
            <span>{t("header.settings")}</span>
          </a> 
        </>
    )
}

export default LoadingUser
