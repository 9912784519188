// @modules
import React from "react";
import { useResponsive } from "../hooks";

// @styles
import styles from "./Footer.module.scss";

// @components
import FooterDesktop from "./footer-desktop/FooterDesktop";
import BottomContent from "./bottom-content/BottomContent";
import FooterMobile from "./footer-mobile/FooterMobile";

function Footer() {
  const responsive = useResponsive();

  return (
    <>
      {/* <div className={ styles.blueLine } />
      <div className={ styles.yellowLine } /> */}
      <div className={ styles.footer }>
        <div className={ styles.content }>
          {responsive ? <FooterMobile /> : <FooterDesktop />}
       
          <BottomContent />
        </div>
      </div>
    </>
  );
}

export default Footer;
