// @modules
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

// @assets
import { useSetURL } from "../../hooks";
import HeaderDropdown from "../HeaderDropdown";

const Resources = () => {
  const { t } = useTranslation();

  const setURL = useSetURL();

  const resources = useMemo(
    () => [
      {
        label: t("header.askAnExpert"),
        href: setURL("contact-us", true),
      },
      { label: t("header.howItWorks"),
        href: setURL("faqs", true) },
    ],
    [setURL, t],
  );

  return <HeaderDropdown items={ resources } label={ t("header.resources") } />;
};

export default Resources;
