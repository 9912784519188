// @assets
import { faStar, faStarHalf } from "@fortawesome/free-solid-svg-icons";
// @styles
import "./StarRating.scss";
// @modules
import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const generateStarsIcon = stars => {
  const starsIcon = [];

  for (let i = 0; i < Math.floor(stars); ++i) {
    starsIcon.push(
      <FontAwesomeIcon className="star-rating__star" icon={ faStar } key={ `star-${i}` } />,
    );
  }

  if (Math.floor(stars) < Math.ceil(stars)) {
    starsIcon.push(
      <FontAwesomeIcon
        className="star-rating__star"
        icon={ faStarHalf }
        key={ `star-${starsIcon.length}` }
      />,
    );
  }

  return starsIcon;
};

function StarRating({ stars }) {
  const starsIcon = useMemo(() => generateStarsIcon(stars), [stars]);

  return (
    <div className="star-rating">
      { starsIcon }
    </div>
  );
}

StarRating.propTypes = {
  stars: PropTypes.number.isRequired,
};

export default StarRating;
