// @styles
import "./InputCountriesSelector.scss";
// @components
import Label from "react-bootstrap/FormLabel";
// @modules
import React, { useEffect, useState } from "react";
import CountryDropdown from "../country-dropdown";
import Proptypes from "prop-types";

function InputCountriesSelector({
  disabled,
  from,
  filterFrom,
  filterTo,
  handleChange,
  labelFrom = null,
  labelTo = null,
  name = "countrySelector",
  to,
}) {
  const [sendingCountries, setSendingCountries] = useState({
    from,
    to,
  });

  const handleSelect = (key, country) => {
    const newSendingCountries = {
      ...sendingCountries,
      [key]: country,
    };

    setSendingCountries(newSendingCountries);
    handleChange(newSendingCountries);
  };

  useEffect(() => {
    setSendingCountries({
      from,
      to,
    });
  }, [from, to]);

  return (
    <div className="crediframework-input-countries-selector">
      <div className="crediframework-input-countries-selector__selectors">
        <div className="crediframework-input-countries-selector__selector-from">
          <Label>{labelFrom}</Label>
          <CountryDropdown
            countryCode={ from }
            disabled={ disabled }
            filter={ filterFrom }
            name={ `${name}From` }
            onSelect={ country => handleSelect("from", country) }
            value={ from }
          />
        </div>

        <div className="crediframework-input-countries-selector__selector-to">
          <Label>{labelTo}</Label>
          <CountryDropdown
            countryCode={ to }
            disabled={ disabled }
            filter={ filterTo }
            name={ `${name}To` }
            onSelect={ country => handleSelect("to", country) }
            remove={ from } value={ to }
          />
        </div>
      </div>
    </div>
  );
}

InputCountriesSelector.propTypes = {
  from: Proptypes.string.isRequired,
  handleChange: Proptypes.func,
  label: Proptypes.string,
  name: Proptypes.string,
  to: Proptypes.string.isRequired,
};

export default InputCountriesSelector;
