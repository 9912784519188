import useWindowSize from "./useWindowSize";

// Hook
// Pass responsive design width as an argument, by default it is 800px
// Returns a boolean describing if the screen width is less than responsive width
const useResponsive = (responsiveWidth = 800) => {
  const { width: screenWidth } = useWindowSize();
  return screenWidth <= responsiveWidth;
};

export default useResponsive;
