const currency = [
  {
    id: "mxn",
    name: "MXN",
    label: "MXN",
    value: "mxn",
  },
  {
    id: "usd",
    name: "USD",
    label: "USD",
    value: "usd",
  },
  {
    id: "gtq",
    name: "GTQ",
    label: "GTQ",
    value: "gtq",
  },
  {
    id: "nio",
    name: "NIO",
    label: "NIO",
    value: "nio",
  },
  {
    id: "hnl",
    name: "HNL",
    label: "HNL",
    value: "hnl",
  },
  {
    id: "svc",
    name: "SVC",
    label: "SVC",
    value: "svc",
  },
];

export default currency;
