// @modules
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useMemo,
  useRef,
} from "react";
import { initialState, reducer } from "./reducer";
import {
  getNotifications,
  toggleNotificationStatus,
} from "../common/api/api.notifications";
// @assets
// import { NEW_DUMMY } from "./dummy";
import {
  MARK_READ,
  MARK_UNREAD,
  SET_NEW_NOTIFICATIONS_NUMBER,
  SET_NOTIFICATIONS,
  TOGGLE_READ,
} from "./actions";
import { useCrediverso } from "../provider";

const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { crediversoState } = useCrediverso();
  // initial state update
  const reqSent = useRef(false);
  useEffect(() => {
    if (!reqSent.current && crediversoState.user.id !== undefined) {
      getNotifications().then(res => {
        const data = res.data.details;
        const notifications = data.notification
          .map((e, i) => {
            const date = new Date(e.details.alertDate).toLocaleDateString(
              "en-US",
              {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              },
            );
            return {
              date: date,
              description: "Some dummy notification " + i,
              details: e.details,
              id: e.alertId,
              impact: e.impact,
              read: parseInt(e.read) === 1,
              section: "Credit Checks",
              type: e.type,
              title: e.name,
              tipDescription: e.tipDescription,
            };
          })
          .sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
          );
        const newNotifications = notifications.filter(e => !e.read).length;
        dispatch({
          type: SET_NEW_NOTIFICATIONS_NUMBER,
          payload: newNotifications,
        });
        dispatch({ type: SET_NOTIFICATIONS,
          payload: notifications });
      });
      reqSent.current = true;
    }
  }, [state, crediversoState.user.id]);

  // methods

  const markRead = useCallback(
    notificationId => {
      const selectedNotification = state.notifications.find(
        e => e.id === notificationId,
      );
      if (selectedNotification && !selectedNotification.read)
        toggleNotificationStatus(notificationId);

      dispatch({ type: MARK_READ,
        payload: notificationId });
    },
    [state.notifications],
  );

  const markUnread = useCallback(
    notificationId => {
      const selectedNotification = state.notifications.find(
        e => e.id === notificationId,
      );
      if (selectedNotification && selectedNotification.read)
        toggleNotificationStatus(notificationId);

      dispatch({ type: MARK_UNREAD,
        payload: notificationId });
    },
    [state.notifications],
  );

  const toggleRead = useCallback(notificationId => {
    toggleNotificationStatus(notificationId);
    dispatch({ type: TOGGLE_READ,
      payload: notificationId });
  }, []);

  const latestUnreadNotification = useMemo(() => {
    try {
      if (state.notifications)
        return state.notifications.find(e => e.read === false);
    }
    catch {}
  }, [state]);

  return (
    <NotificationsContext.Provider
      value={ {
        ...state,
        markRead,
        markUnread,
        toggleRead,
        latestUnreadNotification,
      } }
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationsContext);
export default NotificationsProvider;
