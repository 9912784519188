// @modules
import { memo, useCallback, useState } from "react";

// @assets
import { ArrowDownGray } from "../../../../assets";

// @styles
import styles from "./HeaderDropdown.module.scss";

const HeaderDropdown = memo(({ label, items }) => {
  const [show, setShow] = useState(false);

  const open = useCallback(() => setShow(true), []);
  const close = useCallback(() => setShow(false), []);

  return (
    <div className={ styles.container } onMouseLeave={ close } onMouseOver={ open }>
      {label}
      <img alt="" src={ ArrowDownGray } />
      {show && (
        <ul className={ styles.resources }>
          {items.map(e => (
            <li key={ e.label }>
              <a href={ e.href }>{e.label}</a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
});

export default HeaderDropdown;
