import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "../components/header";
// import HeaderOld from "../components/header-old";
import Footer from "../components/footer";
import Home from "../views/home/Home";
import JoinAccount from "../views/register";
import Login from "../views/login";
import Notification from "../components/notification/Notification";

const RemittancesRouter = lazy(() => import("./RemittancesRouter"));
// const CommunityRouter = lazy(() => import("./CommunityRouter"));
const MediaCenterRouter = lazy(() => import("./MediaCenterRouter"));
const CreditChecksRouter = lazy(() => import("./CreditChecksRouter"));
const SecureCardRouter = lazy(() => import("./SecureCardRouter"));
const AutoInsuranceRouter = lazy(() => import("./AutoInsuranceRouter"));
const NotificationCenter = lazy(() => import("../views/notification-center"));
const SignUp = lazy(() => import("../views/signup"));

export default function AppRouter() {
  return (
    <Router basename="/">
      <Header />
      {/* <HeaderOld /> */}
      <div className="crediverso__body">
        <Suspense
          fallback={
            <div>
              <h5>Loading page...</h5>
            </div>
          }
        >
          <Switch>
            <Route component={ Home } exact path="/" />
            <Route component={ RemittancesRouter } path="/remittances" />
            {/* <Route component={ CommunityRouter } path="/community" /> */}
            <Route component={ JoinAccount } path="/register" />
            <Route component={ CreditChecksRouter } path="/credit_checks" />
            <Route component={ Notification } path="/notification" />

            <Route component={ MediaCenterRouter } path="/media" />
            <Route component={ SecureCardRouter } path="/securecard" />
            <Route component={ AutoInsuranceRouter } path="/autoInsurance" />
            <Route component={ Login } path="/login" />
            <Route component={ SignUp } path="/credit_checks/signup" />
            <Route component={ NotificationCenter } path="/notification-center" />
          </Switch>
        </Suspense>
      </div>
      <Footer />
    </Router>
  );
}
