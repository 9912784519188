import { useTranslation } from "react-i18next";
import { useLang } from "../../hooks";
import styles from "./ContactUs.module.scss";
import {
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  TwitterLogo,
  WorldLogo,
} from "../../../assets";

const ContactUs = () => {
  const { t } = useTranslation();
  const { switchLang } = useLang();
  return (
    <div className={ styles.container }>
      <p className={ styles.title }>{t("footer.upper.contact.title")}</p>

      <a className={ styles.mail } href="mailto:info@crediverso.com">
        info@crediverso.com
      </a>

      <div className={ styles.socialIcons }>
        <FacebookLogo
          onClick={ () =>
            window.open(
              "https://www.facebook.com/Crediverso-Inc-110533187942056/",
            )
          }
        />
        <LinkedinLogo
          onClick={ () =>
            window.open("https://www.linkedin.com/company/crediverso/")
          }
        />
        <InstagramLogo
          onClick={ () => window.open("https://www.instagram.com/crediverso/") }
        />
        <img
          alt="Twitter"
          onClick={ () => window.open("https://twitter.com/crediverso") }
          src={ TwitterLogo }
        />
      </div>
      <div className={ styles.translate } onClick={ switchLang }>
        <img alt="Earth" src={ WorldLogo } />
        <p>{t("footer.upper.contact.translate")}</p>
      </div>
    </div>
  );
};

export default ContactUs;
