import CrediversoLogoSvg from "../../assets/LogoCrediverso.svg";
import { useCrediverso } from "../../provider";
import styles from "./MainLogo.module.scss";
import { getWordPressBaseUrlNewHomepage } from "../../utils/urlUtils";

const MainLogo = () => {
  const { computeURL } = useCrediverso();
  const getBaseUrl = () => {
    let baseURL = "https://dev.raas.crediverso.com/";
    if (window.location.hostname === "uat.raas.crediverso.com")
      baseURL = "https://uat.raas.crediverso.com/";
    else if (window.location.hostname === "app.crediverso.com")
      baseURL = "https://app.crediverso.com/";

    return baseURL;
  };

  const getBaseUrlWp = () => {
    let baseURL = "https://dev.raas.crediverso.com/";
    if (window.location.hostname === "uat.raas.crediverso.com")
      baseURL = "https://uat.raas.crediverso.com/";
    else if (window.location.hostname === "app.crediverso.com")
      baseURL = "https://app.crediverso.com/";

    return baseURL;
  };

  const setURL = (path, isWordPress) => {
    if (!isWordPress)
      return `${getBaseUrl()}${path}`;

    return computeURL(path, isWordPress);
  };

  return (
    <a className={ styles.logo } href={ getWordPressBaseUrlNewHomepage()}>
      <img alt="Crediverso Logo" src={ CrediversoLogoSvg } />
    </a>
  );
};

export default MainLogo;
