import React from "react";
import style from "./Dot.module.scss";

function Dot() {
  return (
    <div className={ style.notificationDotContainer }>
      <div className={ style.dot } />
    </div>
  );
}

export {
  Dot,
};
