// @styles
import "./InputNumber.scss";
// @modules
import React, { useState, forwardRef } from "react";
import Control from "react-bootstrap/FormControl";
import PropTypes from "prop-types";
import { mask as masker, unMask } from "remask";

const InputNumber = forwardRef(({
  label = null,
  name = null,
  ...props
}, ref) => {


  const handleChangeNumber = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = masker(originalValue, ["99999999"]);
    setValue(maskedValue);
  };

  const [value, setValue] = useState("");

  return (
    <Control
      name={ name }
      onChange={ handleChangeNumber }
      ref={ ref }
      type="text"
      value={ value }
      { ...props }
    />
  );
});

InputNumber.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

export default InputNumber;
