// @assets
import errorImage from "../../assets/images/errorModal.svg";
// @styles
import "./ModalError.scss";
// @components
import Button from "../button";
import Modal from "../modal";
// @modules
import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { useModal } from "../../hooks";

function ModalError({
  handleClose,
  isVisible,
  message,
  btnId = "",
  buttonMessage = null,
}) {
  const {
    visible,
    toggleVisibility,
  } = useModal({ initialState: isVisible });

  const onClose = () => {
    toggleVisibility();
    handleClose();
  };

  return (
    <Modal visible={ visible } visibleCloseButton={ false }>
      <div className="crediframework-modal-error">
        <img alt="Error modal icon" className="crediframework-modal-error__image" src={ errorImage } />
        <p className="crediframework-modal-error__main-message">Error</p>
        <p className="crediframework-modal-error__description">
          { message }
        </p>
        <Button color="blue" id={ btnId } onClick={ onClose }>{ buttonMessage || "Try again" }</Button>
      </div>
    </Modal>
  );
}

ModalError.propTypes = {
  isVisible: PropTypes.bool,
};

export default ModalError;
