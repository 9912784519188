import React, { useState, useEffect } from 'react';
import styles from './dropdown.module.scss';
import ErrorIcon from '../../assets/images/error-icon.svg';

const DropDown = ({ id, name, selectDefaultProp = true, onChange, label, options, value, dataPrivate = false, hasError = false, hasErrorIcon = false, errorLabel = "" }) => {

  const [ selectDefault, setSelectDefault ] = useState(true);

  useEffect(() => {
    setSelectDefault(selectDefaultProp);
  }, [selectDefaultProp])
  

  const onChangeSelect = (e) => {
    setSelectDefault(false);
    onChange(e);
  }

  return (
      <div className={`${styles["dropdown"]} ${(selectDefault ? styles['is-default'] : styles['no-default'])}`}>
        {
            label &&
            <label className={styles["label"]} >{label}</label>
        }

        {
          dataPrivate ?
            <select data-private className={styles["select"]} id={id} name={name} value={value} onChange={onChangeSelect} autoComplete="nope">
              <option className={styles["option"]} disabled selected>{name}</option>
                {options.map((o, i) => (
                    <option key={i} className={styles["option"]} value={o} selected={value === o}>{o}</option>
                ))}
            </select> 
          :
            <select className={styles["select"]} id={id} name={name} onChange={() => setSelectDefault(false)} autoComplete="nope">
              <option className={styles["option"]} disabled selected>{name}</option>
                {options.map((o, i) => (
                    <option key={i} className={styles["option"]} value={o} selected={value === o}>{o}</option>
                ))}
            </select>
        }
        <div className={styles["bottom-line"]}/>
        {
          hasError &&
          <div className={styles["error-label"]}>
            {
              hasErrorIcon && 
              <img alt='error-icon' src={ErrorIcon}/>
            }
            <p>{errorLabel}</p>
          </div>
        }
      </div>
  )
}

export default DropDown