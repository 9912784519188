// @modules
import React, { useEffect } from "react";
import { useCrediverso } from "../../provider";

const Home = ({ location }) => {
  const { handleCrossSession } = useCrediverso();

  useEffect(() => {
    handleCrossSession(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h1>Crediverso App</h1>
    </div>
  );
};

export default Home;
