// @styles
import Styles from "./Wizard.module.scss";
// @modules
import PropTypes from "prop-types";
import React, { useContext, Children, useEffect } from "react";
import { MainWizardContext, WizardContext } from "./WizardContext";

import { Step as ViewerStep, Stepper } from "@crediverso/crediframework";

import WizardFunction from './WizardFunction';

function Wizard({ 
        children, 
        stepper, 
        navigation, 
        showProgress, 
        onStepUpdateEnd, 
        stepperClass, 
        onBeforeNext, 
        onBeforePrevious, 
        nextButtonId, 
        previousButtonId
    }) {

    return (
        <WizardContext>
            <WizardFunction 
                nextButtonId={nextButtonId} 
                previousButtonId={previousButtonId} 
                nextCallback={onBeforeNext} 
                previousCallback={onBeforePrevious} 
                stepperClass={stepperClass} 
                onStepUpdateEnd={onStepUpdateEnd} 
                stepper={stepper} 
                showProgress={showProgress} 
                navigationButtons={navigation}
            >
                {children}
            </WizardFunction>
        </WizardContext>
    );
}

Wizard.propTypes = {
    stepper: PropTypes.array,
};

Wizard.defaultProps = {
    stepper: [],
};

export default Wizard;

export {
    MainWizardContext
}
  