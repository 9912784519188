// @styles
import "./Pagination.scss";
// @components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
// @modules
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function Pagination({ currentPage, pages, paginate }) {
  const pageNumbers = Array.from({ length: pages }, (_, key) => key + 1);
  const [activePage, setActivePage] = useState(currentPage);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);

  const updatePage = page => {
    if (page < 1)
      return;

    if (page > pageNumbers.length)
      return;

    setActivePage(page);
    paginate(page);
  };

  return (
    <div className="pagination">
      <ul>
        <li className="angle" onClick={ () => updatePage(activePage - 1) }>
          <FontAwesomeIcon icon={ faAngleLeft } />
        </li>

        {pageNumbers.map((value, index) => {
          return (
            <li
              className={ value === activePage ? "selected" : "" }
              key={ `${index}-pagination` }
              onClick={ () => updatePage(value) }
            >
              { value }
            </li>
          );
        })}

        <li className="angle" onClick={ () => updatePage(activePage + 1) }>
          <FontAwesomeIcon icon={ faAngleRight } />
        </li>
      </ul>
    </div>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  paginate: PropTypes.func,
};
