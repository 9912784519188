import {
  ADD_CONFIRMED_STEP,
  SET_CURRENT_SELECTED_STEP,
} from "./actionTypes";

export const addConfirmedStep = payload => ({
  payload,
  type: ADD_CONFIRMED_STEP,
});

export const setCurrentSelectedStep = payload => ({
  payload,
  type: SET_CURRENT_SELECTED_STEP,
});
