import i18n from "../translations/i18n";

const getWordPressBaseUrl = () => {
  const lang = i18n.language.includes("es") ? "es" : "en";
  let baseURL = `https://www3-dev.crediverso.com/${lang}/`;
  if (window.location.hostname === "uat.raas.crediverso.com")
    baseURL = `https://www3-uat.crediverso.com/${lang}/`;
  else if (window.location.hostname === "app.crediverso.com")
    baseURL = `https://www3.crediverso.com/${lang}/`;
  return baseURL;
};

const getWordPressBaseUrlTemp = () => {
  const lang = i18n.language.includes("es") ? "es" : "en";
  let baseURL = `https://www3-dev.crediverso.com/${lang}/`;
  if (window.location.hostname === "uat.raas.crediverso.com")
    baseURL = `https://www3-uat.crediverso.com/${lang}/`;
  else if (window.location.hostname === "app.crediverso.com")
    baseURL = `https://www3.crediverso.com/${lang}/`;
  return baseURL;
};

const getWordPressBaseUrlNewHomepage = () => {
  const lang = i18n.language.includes("es") ? "es" : "en";
  let baseURL = `https://dev.crediverso.com/${lang}/`;
  if (window.location.hostname === "uat.raas.crediverso.com")
    baseURL = `https://uat.crediverso.com/${lang}/`;
  else if (window.location.hostname === "app.crediverso.com")
    baseURL = `https://crediverso.com/${lang}/`;
  return baseURL;
};

const getCleanURLQuery = () => {
  let query = window.location.search.split("?");
  if (query.length > 1) {
    query.forEach(element => {
      // eslint-disable-next-line
      if (element.length == 0) {
        query.splice(0, 1);
      }
    });
  }
  query.join("&");
  query = "/?" + query;
  return query;
};

export {
  getWordPressBaseUrl,
  getWordPressBaseUrlTemp,
  getWordPressBaseUrlNewHomepage,
  getCleanURLQuery,
};
