// @modules
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// @components
import Control from "react-bootstrap/FormControl";
// @assets
import errorIcon from "../../assets/images/input/error-icon-message.svg";

function InputEmail({ placeholder, ...props }) {
  const [valid, setValid] = useState();
  const { t } = useTranslation();

  const validateEmail = useCallback(event => {
    const email = event.target.value;
    const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
    if (regex.test(String(email)))
      setValid(true);
    else
      setValid(false);
  }, []);

  const handleChange = useCallback(
    event => {
      validateEmail(event);
      if (props.onChange)
        props.onChange(event);
    },
    [props, validateEmail],
  );

  return (
    <>
      <Control
        err
        placeholder={ placeholder }
        type="email"
        { ...props }
        onChange={ handleChange }
      />
      {valid === false && (
        <span className="input__error-message">
          <img alt="error" src={ errorIcon } />
          {t("form.error.invalidEmail")}
        </span>
      )}
    </>
  );
}

InputEmail.propTypes = {
  placeholder: PropTypes.string,
};

export default InputEmail;
