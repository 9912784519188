// @styles
import "./Button.scss";
// @components
import CloseButton from "../close-button";
import BootstrapButton from "react-bootstrap/Button";
// @modules
import React from "react";
import PropTypes from "prop-types";
import AccordionCollapseButton from "../accordion-collapse-button";

const buttons = {
  submit: "primary",
};

function Button({
  children = "",
  className = "",
  color = null,
  type = null,
  ...props
}) {
  if (color) {
    return (
      <button
        className={ `crediframework-button crediframework-button--${color} ${className}` }
        { ...props }
      >
        {children}
      </button>
    );
  }

  if (type === "close")
    return <CloseButton className={ className } { ...props } />;

  if (type === "accordion-collapse")
    return <AccordionCollapseButton className={ className } { ...props } />;

  return (
    <BootstrapButton
      className={ className }
      type={ type }
      variant={ buttons[type] }
      { ...props }
    >
      {children}
    </BootstrapButton>
  );
}

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
};

export default Button;
