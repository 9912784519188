// @modules
import React from "react";
import PropTypes from "prop-types";
import Control from "react-bootstrap/FormControl";

function InputText({
  placeholder,
  ...props
}) {
  return (
    <Control
      placeholder={ placeholder }
      type="text"
      { ...props }
    />
  );
}

InputText.propTypes = {
  placeholder: PropTypes.string,
};

export default InputText;
