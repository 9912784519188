// @components
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
// @modules
import React from "react";
import PropTypes from "prop-types";

function DatePicker({
  yeardefault,
  ...props
}) {
  const currentYear = (new Date()).getFullYear();

  const years = Array.from({ length: 100 }, (_, i) => currentYear - i).sort((a, b) => a - b);
  const months = Array.from({ length: 12 }, (_, i) => 12 - i).sort((a, b) => a - b);
  const days = Array.from({ length: 31 }, (_, i) => 31 - i).sort((a, b) => a - b);

  return (
    <>
      <Form.Label>{ props.label }</Form.Label>
      <Row>
        <Col>
          <Form.Control
            as="select"
            className="form-control-lg"
            custom
            defaultValue="01"
            name="month"
            { ...props }
          >
            <option hidden value="01">MM</option>
            {
              months.map(month => (
                <option key={ month } value={ month.toLocaleString("en-US", { minimumIntegerDigits: 2,
                  useGrouping: false }) }>
                  { month }
                </option>
              ))
            }
          </Form.Control>
        </Col>

        <Col>
          <Form.Control
            as="select"
            className="form-control-lg"
            custom
            defaultValue="01"
            name="day"
            { ...props }
          >
            <option hidden value="01">DD</option>
            { days.map(day => (
              <option key={ day } value={ day.toLocaleString("en-US", { minimumIntegerDigits: 2,
                useGrouping: false }) }>
                { day }
              </option>
            )) }
          </Form.Control>
        </Col>

        <Col>
          <Form.Control
            as="select"
            className="form-control-lg"
            custom
            defaultValue="default"
            name="year"
            { ...props }
          >
            <option hidden value="1990">{ yeardefault }</option>
            { years.map(year => (
              <option key={ year } value={ year }>
                { year }
              </option>
            )) }
          </Form.Control>
        </Col>
      </Row>
    </>
  );
}

DatePicker.propTypes = {
  day: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
  yearDefault: PropTypes.string,
};

export default DatePicker;
