// @styles
import "./ModalHeader.scss";
// @components
import Button from "../button";
// @modules
import React from "react";
import PropTypes from "prop-types";

function Modalheader({
  onClose,
  visibleCloseButton,
}) {
  return (
    <div className="crediframework-modal-header">
      { visibleCloseButton &&
        <Button onClick={ onClose } type="close" visibleCloseButton={ visibleCloseButton } />
      }
    </div>
  );
}

Modalheader.propTypes = {
  onClose: PropTypes.func,
  visibleCloseButton: PropTypes.bool.isRequired,
};

export default Modalheader;
