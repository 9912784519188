// @components
import InputEmail from "../input-email";
import InputAmount from "../input-amount";
import InputNumber from "../input-number";
import InputCard from "../input-card";
import InputPassword from "../input-password";
import InputPhone from "../input-phone";
import InputText from "../input-text";
import InputCardExpiration from "../input-card-expiration";
import InputCardCvv from "../input-card-cvv";
import InputRoutingNumber from "../input-routing-number";
import InputZipCode from "../input-zip-code";
import InputClabe from "../input-clabe";

const inputs = {
  amount: InputAmount,
  card: InputCard,
  cardCvv: InputCardCvv,
  cardExpiration: InputCardExpiration,
  clabe: InputClabe,
  email: InputEmail,
  number: InputNumber,
  password: InputPassword,
  phone: InputPhone,
  routingNumber: InputRoutingNumber,
  text: InputText,
  zipCode: InputZipCode,
};

export default inputs;
