import axios from "./axios";

export const validateError = response => {
  if (response.code)
    throw new Error(`There was an error ${response.code}`);
};

const getHeaders = async session => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (!session) {
    return {
      headers,
    };
  }
  const { data: dataResponseAuth } = await axios.post(
    "remittance/auth",
    {
      password: "7N9s1Kiag43J",
      username: "admin",
    },
    {
      headers,
    },
  );
  const { details } = dataResponseAuth;
  return {
    headers: {
      ...headers,
      Session: details[0].session,
    },
  };
};

const getGeneralHeaders = async session => {
  const headers = {
    "Content-Type": "application/json",
  };

  const userToken = window.localStorage.getItem("userToken");

  if (!session && userToken !== null) {
    return {
      headers,
    };
  }

  //This is in order to not send Session: null
  if (userToken === null) {
    return {
      headers,
    };
  }

  return {
    headers: {
      ...headers,
      Session: userToken,
    },
  };
};

export const get = async(endpoint, session = false) => {
  try {
    const headers = await getHeaders(session);
    const response = await axios.get(endpoint, headers);
    return response;
  }
  catch (error) {
    throw new Error(`${error.toString()} in GET method.`);
  }
};

export const post = async(endpoint, body, session = false) => {
  try {
    const headers = await getHeaders(session);
    const response = await axios.post(endpoint, body, headers);

    return response;
  }
  catch (error) {
    throw new Error(`${error.toString()} in POST method.`);
  }
  finally {
    return null;
  }
};

export const general_post = async(endpoint, body, session = false) => {
  try {
    const headers = await getGeneralHeaders(session);
    const response = await axios.post(endpoint, body, headers);

    return response;
  }
  catch (error) {
    return error.response;
  }
};
