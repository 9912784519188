// @styles
import Styles from "./Wizard.module.scss";
// @modules
import PropTypes from "prop-types";
import React, { useContext, Children, useEffect, useState } from "react";
import { MainWizardContext, WizardContext } from "./WizardContext";

import { Step as ViewerStep, Stepper } from "@crediverso/crediframework";
import {
    faAngleRight,
    faAngleLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setCurrentSelectedStep } from "@crediverso/remittances/src/redux/remittance/actions/steps/actionCreators";

function Wizard({ 
        children, 
        stepper, 
        navigationButtons, 
        showProgress, 
        onStepUpdateEnd, 
        stepperClass, 
        nextCallback,
        previousCallback,
        nextButtonId, 
        previousButtonId
    }) {
    
    const { 
        currentStep,
        setCurrentStep,
        stepsLength,
        next,
        nextButtonVisible,
        nextLabel,
        nextDisabled,
        setNextDisabled,
        previous,
        previousLabel,
        previousDisabled,
        setPreviousDisabled,
        previousButtonVisible,
        stepperVisible,
        setStepperVisible,
        setStepsLength,
        isLoading
    } = useContext(MainWizardContext);

    let auxSteps = Children.map(children, (child, index) => {
        return child;
    });
    const [ childSteps, setChildSteps] = useState(auxSteps);
    const [ wizardInit, setWizardInit ] = useState(false);

    useEffect(() => {
        try {
            stepper.map( step => {
                step['active'] = false;
            });

            stepper[currentStep]['active'] = true;
        } catch(e) { }

        try {
            setStepsLength((childSteps.length - 1));
            setStepperVisible(showProgress);
        } catch(e) {}
    }, []);

    useEffect(() => {
        if (wizardInit) {
            onStepUpdateEnd(currentStep);
        }
        setWizardInit(true);
        
    }, [currentStep]);

    async function customNext() {
        let nextCallbackValue = false;

        setNextDisabled(true);
        
        if (!!nextCallback) {
            nextCallbackValue = await nextCallback(currentStep);
            
            if (nextCallbackValue === true) {
                next();
            } else if (typeof nextCallbackValue == 'number') {
                setCurrentStep(nextCallbackValue);
            }
        }
        
        setNextDisabled(false);
        return nextCallbackValue;
    }

    async function customPrevious() {
        let previousCallbackValue = false;

        setPreviousDisabled(true);
        
        if (!!previousCallback) {
            previousCallbackValue = await previousCallback(currentStep);
            
            if (previousCallbackValue === true || previousCallbackValue === undefined) {
                previous();
            } else if (typeof previousCallbackValue == 'number') {
                setCurrentStep(previousCallbackValue);
            }
        }
        
        setPreviousDisabled(false);
        return previousCallbackValue;
    }

    return (
        <>
            {
                stepper.length && stepperVisible ? 
                <Stepper step={(currentStep + 1)} className={`pt-2 ${stepperClass} ${Styles['displayed-cch-stepper']}`}>
                    {   
                        stepper.map( (step, index) => {
                            try {
                                return <ViewerStep key={`stepper-key-${index}`} icon={step.icon} label={step.label} image={step.image} />
                            } catch(e) {}
                        })
                    }
                </Stepper>
                :
                <></>
            }
            
            {!isLoading ?
                    childSteps.length ?
                        React.cloneElement(childSteps[currentStep])
                    :
                        <></>
                :
                    <>Loading</>
            }

            {   
                navigationButtons &&
                (
                    <div className={`navigation-row-${currentStep} navigation-row ${Styles['navigation-row']}`}>
                        {
                            previousButtonVisible ?
                            <button id={previousButtonId} disabled={previousDisabled} onClick={customPrevious} className={`general-navigation-button ${Styles["button-backButton"]}`} > 
                                <div className={`${Styles['text-wrapper']} previous-button`}>
                                    <FontAwesomeIcon className={`${Styles['previous-icon']}`} icon={ faAngleLeft } /> 
                                    <span className="capitalize-first-letter">{previousLabel}</span>
                                </div>
                            </button>
                            :
                            <></>
                        }
                        { 
                            nextButtonVisible ? 
                            <button id={nextButtonId} disabled={nextDisabled} onClick={customNext} className={`general-navigation-button ${Styles["button-primaryButton"]}`}>
                                <div className={`${Styles['text-wrapper']} next-button`}>
                                    <span className="capitalize-first-letter">{nextLabel}</span> 
                                    <FontAwesomeIcon className={`${Styles['next-icon']}`} icon={ faAngleRight } />
                                </div>
                            </button>
                            :
                            <></>
                        }
                    </div>
                )
            }
        </>
    );
}

Wizard.propTypes = {
    step: PropTypes.number,
};

export default Wizard;
