// @styles
import "./Modal.scss";
// @components
import Modalheader from "./Modalheader";
// @modules
import React from "react";
import PropTypes from "prop-types";
import { useModal } from "../../hooks";

function Modal({
  children,
  onClose = () => {},
  visible = false,
  visibleCloseButton = false,
  className,
}) {
  const {
    visible: visibleModal,
    toggleVisibility,
  } = useModal({ initialState: visible });

  const onCloseModal = () => {
    onClose();
    toggleVisibility();
  };

  return (
    <div className={ `crediframework-modal ${className} ${visibleModal ? "" : "crediframework-modal--hidden"}` }>
      <div className="crediframework-modal__card">
        <Modalheader onClose={ onCloseModal } visibleCloseButton={ visibleCloseButton } />
        <div className="crediframework-modal__body">
          { children }
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  children: PropTypes.any,
  visible: PropTypes.bool,
  visibleCloseButton: PropTypes.bool,
};

export default Modal;
