import React, { useReducer, useContext, useMemo } from "react";
import { initialState, reducer } from "../reducer/crediversoReducer";
import { SET_USER, IS_USER_LOADING } from "../actions/crediversoActions.js";
import PropTypes from "prop-types";
import { getCleanURLQuery, getWordPressBaseUrl, getWordPressBaseUrlNewHomepage, getWordPressBaseUrlTemp } from "../utils/urlUtils";
import { validateToken } from "../common/api";
import LogRocket from "logrocket";

export const CrediversoContext = React.createContext();

export const TOKEN_KEY = "_ttau_cr";
export const USER_TOKEN_SIGN_UP_CREDICHECKS_KEY = "userToken";
export const PYTHON_TOKEN_KEY = "userToken";
export const USER_KEY = "CREDIVERSO_USER";

const CrediversoProvider = ({ children, location }) => {
  const [crediversoState, crediversoDispatch] = useReducer(
    reducer,
    initialState || {},
  );

  const getAuthState = () => {
    return (
      localStorage.getItem(USER_KEY) !== null &&
      localStorage.getItem(TOKEN_KEY) !== null
    );
  };

  const getUserInfo = () => {
    return JSON.parse(localStorage.getItem(USER_KEY));
  };

  const updateUserInfo = data => {
    window.localStorage.setItem(USER_KEY, JSON.stringify(data));
    crediversoDispatch({
      type: SET_USER,
      payload: data,
    });
  };

  const handleLogin = (data, token, python_token, debug = false) => {
    window.localStorage.setItem(USER_KEY, JSON.stringify(data));
    window.localStorage.setItem(TOKEN_KEY, token);
    window.localStorage.setItem(PYTHON_TOKEN_KEY, python_token);

    const date = new Date();
    document.cookie = `_ttau_cr=${token}; expires=${date.setDate(
      date.getDate() + 1,
    )}; path=/; domain=${(debug ? 'localhost' : '.crediverso.com')}; Secure;`;

    crediversoDispatch({
      type: SET_USER,
      payload: data,
    });

    crediversoDispatch({
      type: IS_USER_LOADING,
      payload: false,
    });
  };

  const computeURL = (path, isWordPress, newHome = false) => {
    const base = newHome ? getWordPressBaseUrlNewHomepage() : getWordPressBaseUrl();
    const query = getCleanURLQuery();

    if (!isWordPress)
      return query.split("?")[1];

    return `${base}${path}`;
  };

  const setToken = token => window.localStorage.setItem(TOKEN_KEY, token);

  const handleLogOut = () => {

    localStorage.clear();
    document.cookie = `${TOKEN_KEY}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

    crediversoDispatch({
      payload: {},
      type: SET_USER,
    });
  };

  const checkSessionAlive = () => {
    if (getCookie("_ttau_cr") === undefined)
      handleLogOut();
    else if (localStorage.getItem(USER_KEY) !== null) {
      return crediversoDispatch({
        type: SET_USER,
        payload: JSON.parse(localStorage.getItem(USER_KEY)),
      });
    }

    handleCrossSession({ location });
    return false;
  };

  const getCookie = name => {
    const cookieValue = `; ${document.cookie}`;
    const parts = cookieValue.split(`; ${name}=`);
    if (parts.length === 2)
      return parts.pop().split(";").shift();
  };

  const loadAnalytics = (profile, response) => {
    if (window.location.hostname === "app.crediverso.com") {
      LogRocket.identify(profile.user_email, {
        name: profile.display_name,
        email: profile.user_email,
        prefLanguage: response["py-token"].details[0].language,
        databaseUserId: profile.id,
        ssn: profile.ssn,
      });
    }
    window.heap.identify(profile.user_email);
    window.heap.addUserProperties({
      Name: profile.display_name,
      Email: profile.user_email,
      PrefLanguage: response["py-token"].details[0].language,
      databaseUserID: profile.id,
      ssn: profile.ssn,
    });
  };

  const handleCrossSession = async location => {
    const query = new URLSearchParams(location.search);
    const token = getCookie("_ttau_cr");
    const logout = query.get("logout");
    if (token != null) {
      crediversoDispatch({
        payload: true,
        type: IS_USER_LOADING,
      });
      const body = {
        jwt: token,
      };
      try {
        const resp = await validateToken(body);
        const { profile, py_token } = resp.data.details;
        const userData = {
          name: profile.display_name,
          id: profile.id,
          picture_url: profile.picture_url,
          role: profile.roles[0],
          email: profile.user_email,
        };
        const session = py_token.details[0].session;
        await handleLogin(userData, token, session); 
        await loadAnalytics(profile, resp);
      }
      catch (err) {
        //TODO:Replace this error
        //console.log("Error", err);
      }
    }
    if (logout)
      handleLogOut();
  };

  const handleSimpleLogin = async token => {

    if (!token) { return false; }

    crediversoDispatch({
      payload: true,
      type: IS_USER_LOADING,
    });
    
    const body = {
      jwt: token,
    };
    try {
      const resp = await validateToken(body);
      const { profile, py_token } = resp.data.details;
      const userData = {
        name: profile.display_name,
        id: profile.id,
        picture_url: profile.picture_url,
        role: profile.roles[0],
        email: profile.user_email,
      };
      const session = py_token.details[0].session;
      await handleLogin(userData, token, session); 
      // await loadAnalytics(profile, resp.data.details);

      return true;
    } catch (err) {
      //TODO:Replace this error
      console.log("Error", err);
    }
  }

  const idleLoader = () => {
    var time;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;

    function logout() {
      handleLogOut();
      const currentPath = getWordPressBaseUrlNewHomepage();
      // const currentPath = `${getWordPressBaseUrlNewHomepage()}log-out/?raas=${
      //   window.location.pathname
      // }`;
      window.location.replace(currentPath);
    }

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(logout, 15 * 60 * 1000);
    }
  };

  const value = useMemo(() => {
    return {
      crediversoDispatch,
      crediversoState,
      getAuthState,
      getUserInfo,
      updateUserInfo,
      handleLogin,
      handleSimpleLogin,
      handleCrossSession,
      checkSessionAlive,
      handleLogOut,
      setToken,
      computeURL,
      idleLoader,
      loadAnalytics,
      getCookie,
    };
    // eslint-disable-next-line
  }, [crediversoState]);

  return (
    <CrediversoContext.Provider value={ value }>
      {children}
    </CrediversoContext.Provider>
  );
};

CrediversoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useCrediverso = () => useContext(CrediversoContext);
export default CrediversoProvider;
