import React from "react";
import style from "./Devider.module.scss";

function Devider() {
  return (
    <div className={ style.notificationDeviderContainer }>
      <div className={ style.devider } />
    </div>

  );
}

export { Devider };
