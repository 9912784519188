import { get, post, general_post, validateError } from "./request";

export const createJob = service => {
  return get(`remittance/${service}/jobs/create`, true);
};

export const cancelJob = (service, jobId) => {
  return get(`remittance/${service}/jobs/cancel/${jobId}`, true);
};

export const checkJobStatus = (service, jobId) => {
  return get(`remittance/${service}/jobs/fetch/${jobId}`, true);
};

export const setInputJob = (service, jobId, inputKey, inputData) => {
  return post(
    `remittance/${service}/jobs/inputs/${inputKey}/${jobId}`,
    inputData,
    true,
  );
};

export const validateJobState = async(service, jobId) => {
  const response = await checkJobStatus(service, jobId);

  validateError(response);

  const { details } = response;

  if (details.length === 0)
    throw new Error("There are not details for the job.");

  if (details[0].state === "fail")
    return false;

  return true;
};

export const getPanToken = async() => {
  const response = await post("remittance/pan", {}, true);

  validateError(response);

  if (response.details.length === 0)
    throw new Error("There are not details for the job.");

  return response.details[0].panToken;
};

export const validateEmailRegistered = params => {
  return general_post("credit_checks/validate-email", params, false);
};


export const validateRegister = params => {
  return general_post("credit_checks/validate-register", params, false);
};

export const callSnapshotData = params => {
  return general_post("credit_checks/callSnapshotData", params, true);
};

export const callDisplayReport = params => {
  return general_post("credit_checks/callDisplayReport", params, true);
};

export const callQuestions = params => {
  return general_post("credit_checks/connect-kba", params, true);
};

export const submitQuestions = params => {
  return general_post("credit_checks/submit-kba", params, true);
};

export const validateUser = async params => {
  await general_post("credit_checks/validate-user", params, true);
  await sleep(3000);
  return general_post("credit_checks/validate-user", params, true);
};

export const validateLogin = async params => {
  await general_post("credit_checks/validate-login", params, true);
  await sleep(3000)
  return general_post("credit_checks/validate-login", params, true);
};


export const connectValidate = async params => {
  await general_post("credit_checks/connect-validate-endpoint", params, true);
  await sleep(3000);
  return general_post("credit_checks/connect-validate-endpoint", params, true);
};

export const callOrderCreditReport = params => {
  return general_post("credit_checks/call-creditreport", params, true);
};

export const signUpCCH = params => {
  return general_post("credit_checks/signUp_CCH", params, true);
};

export const callStatusSSN = params => {
  return general_post("credit_checks/call-status-ssn", params, true);
};

export const validateToken = async params => {
  await general_post("credit_checks/connect-validate-endpoint", params, true);
  await sleep(3000);
  return general_post("credit_checks/connect-validate-endpoint", params, false);
};


const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};