const calculateScorePosition = score => {
  const minScore = 300;
  const maxScore = 850;
  const left =
    Math.round(((score - minScore) / (maxScore - minScore)) * 100) + "%";

  const borderColor =
    score >= 780
      ? "#64bf40"
      : score >= 660
        ? "#41b6e6"
        : score >= 600
          ? "#f5b308"
          : "#f83706";

  return { left,
    borderColor };
};

export default calculateScorePosition;
