// @modules
import React, { useEffect } from "react";
import { useLang, useResponsive } from "../hooks";
import { useCrediverso } from "../../provider/index"; 

// @styles
import styles from "./Header.module.scss";

// @components
import HeaderMobile from "./sections/HeaderMobile/HeaderMobile";
import HeaderIpad from "./sections/HeaderIpad/HeaderIpad";
import MenuRight from "./sections/MenuRight/MenuRight";
import MainLogo from "../main-logo/MainLogo";

// @assets
import MenuLeft from "./sections/MenuLeft/MenuLeft";

const Header = () => {
  const { checkSessionAlive, idleLoader } = useCrediverso();
  const sessionAlive = () => checkSessionAlive();

  window.onload = () => {
    idleLoader();
  };

  useEffect(() => {
    sessionAlive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { changeLang } = useLang();

  const ipadResponsive = useResponsive(1300);
  const mobileResponsive = useResponsive(800);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const queryLang = query.get("lang");
    if (queryLang)
      changeLang(queryLang);
  }, [changeLang]);

  return (
    <div className={ styles["app-header"] }>
      <div className={ styles["header-container"] }>
        <MainLogo />
        <div className={ styles["header-content"] }>
          {mobileResponsive ? (
            <HeaderMobile />
          ) : ipadResponsive ? (
            <HeaderIpad />
          ) : (
            <div className={ styles["desktop-container"] }>
              <MenuLeft />
              <MenuRight />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
