import React from "react";
import Card from "react-bootstrap/Card";
import { IconInfo } from "./assets";
import "./InformationBox.scss";

function InformationBox({ label }) {

  return (
    <Card
      className="card-information-box-wise"
      key={ "info.card" }
    >
      <div className="card-information-box-wise-inner-container">
        <div className="card-information-box-wise-icon-container">
          <IconInfo />
        </div>
        <div className="card-information-box-wise-text-container">
          <h6 className="card-information-box-wise-text">
            {label}
          </h6>
        </div>
      </div>
    </Card>
  );

}

export default InformationBox;
