// @modules
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

function useModal({
  initialState,
}) {
  const [visible, setVisible] = useState(initialState);

  useEffect(() => {
    setVisible(initialState);
  }, [initialState]);

  const toggleVisibility = () => setVisible(!visible);

  return {
    toggleVisibility,
    visible,
  };
}

useModal.propTypes = {
  initialState: PropTypes.bool,
};

export default useModal;
