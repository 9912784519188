import {
  MARK_READ,
  MARK_UNREAD,
  SET_NOTIFICATIONS,
  SET_NEW_NOTIFICATIONS_NUMBER,
  TOGGLE_READ,
} from "./actions";

export const initialState = {
  newNotifications: 0,
  notifications: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case MARK_READ:
      if (action.payload) {
        const index = state.notifications.findIndex(
          e => e.id === action.payload,
        );
        if (index === -1)
          return state;
        const newNots = [...state.notifications];
        if (newNots[index].read === true)
          return state;
        newNots[index].read = true;
        return {
          ...state,
          notifications: newNots,
          newNotifications: state.newNotifications - 1,
        };
      }
      break;

    case MARK_UNREAD:
      if (action.payload) {
        const index = state.notifications.findIndex(
          e => e.id === action.payload,
        );
        if (index === -1)
          return state;
        const newNots = [...state.notifications];
        if (newNots[index].read === false)
          return state;
        newNots[index].read = false;
        return {
          ...state,
          notifications: newNots,
          newNotifications: state.newNotifications + 1,
        };
      }
      break;

    case SET_NOTIFICATIONS:
      if (action.payload) {
        return { ...state,
          notifications: action.payload };
      }


      break;

    case SET_NEW_NOTIFICATIONS_NUMBER:
      if (action.payload) {
        return { ...state,
          newNotifications: action.payload };
      }


      break;
    case TOGGLE_READ:
      if (action.payload) {
        const index = state.notifications.findIndex(
          e => e.id === action.payload,
        );
        if (index === -1)
          return state;
        const newNots = [...state.notifications];
        if (newNots[index].read === false) {
          newNots[index].read = true;
          return {
            ...state,
            notifications: newNots,
            newNotifications: state.newNotifications - 1,
          };
        }
        else if (newNots[index].read === true) {
          newNots[index].read = false;
          return {
            ...state,
            notifications: newNots,
            newNotifications: state.newNotifications + 1,
          };
        }
      }
      break;
    default:
      return state;
  }
};
