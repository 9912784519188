// @styles
import styles from "./Loading.module.scss";
//@assets
import LoadingSquare from "../../assets/images/loading.gif";
// @modules
import React from "react";
import PropTypes from "prop-types";

const Loading = () => {
  return (
    <div className={styles["screen-loader"]}>
      <img alt="Loading" src={LoadingSquare}/>
    </div>
  );
};

Loading.propTypes = {
  color: PropTypes.string,
};
export default Loading;
