import React from "react";
// @styles
import "./App.css";
import "./Styles.scss";
import "@crediverso/crediframework/src/styles.scss";
// @ routes
import AppRouter from "./routes/AppRouter";
import CrediversoProvider from "./provider/index";
import NotificationsProvider from "./notifications/context";
import LogRocket from 'logrocket';

function App() {

    LogRocket.init('8kblga/crediverso', {
      rootHostname: 'crediverso.com',
      shouldDebugLog: false
    });
    LogRocket.getSessionURL(function (sessionURL) {
      window.heap.track('LogRocket', { sessionURL: sessionURL });
    });

  return (
    <CrediversoProvider>
      <NotificationsProvider>
        <AppRouter />
      </NotificationsProvider>
    </CrediversoProvider>
  );
}

export default App;
