// @modules
import React, { useCallback, useRef, useState } from "react";
import { getWordPressBaseUrl } from "../../../../utils/urlUtils";
import { useTranslation } from "react-i18next";
// @components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { SearchIconLight } from "../../../../assets";

// @styles
import styles from "./SearchBar.module.scss";

const SearchBar = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();
  const inputRef = useRef();

  const search = useCallback(e => {
    e.preventDefault();
    window.open(
      `${getWordPressBaseUrl()}?s=${inputRef.current.value.trim()}&lang=en`,
      "_blank",
    );
  }, []);

  const onFormClick = useCallback(() => {
    inputRef.current.focus();
    setIsFocused(true);
  }, []);

  const onFormBlur = useCallback(() => {
    setInputValue("");
    setIsFocused(false);
  }, []);

  const onInputChange = useCallback(e => {
    setInputValue(e.target.value);
  }, []);

  const onClearIconClick = useCallback(() => {
    setIsFocused(true);
  }, []);

  return (
    <form
      className={ `${styles.container} ${
        isFocused ? styles.focused : styles.blurred
      }` }
      onBlur={ onFormBlur }
      onClick={ onFormClick }
      onSubmit={ search }
    >
      <input
        onChange={ onInputChange }
        placeholder={ isFocused ? t("mobile_header.startsearch") : "" }
        ref={ inputRef }
        type="text"
        value={ inputValue }
      />
      {isFocused ? (
        <FontAwesomeIcon
          className={ styles.clearIcon }
          icon={ faTimes }
          onClick={ onClearIconClick }
        />
      ) : (
        <SearchIconLight
          className={ styles.searchIcon }
          onClick={ onClearIconClick }
        />
      )}
    </form>
  );
};

export default SearchBar;
