// @assets
import errorIcon from "../../assets/images/input/error-icon-message.svg";
// @styles
import "./input.scss";
// @components
import Group from "react-bootstrap/FormGroup";
import inputs from "./inputs";
import Label from "react-bootstrap/FormLabel";
// @modules
import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const Input = forwardRef(({ className, error, label = null, type, ...props }, ref) => {
  const CurrentInput = inputs[type];

  return (
    <Group>
      {label && <Label>{label}</Label>}
      <CurrentInput
        className={ `${className} ${
          !!error ? "crediframework-input--error" : ""
        }` }
        ref={ ref }
        type={ type }
        { ...props }
      />
      {!!error && (
        <span className="input__error-message">
          <img alt="error" src={ errorIcon } />
          {error.message}
        </span>
      )}
    </Group>
  );
});

Input.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
};

export default Input;
