const states = {
  us: [
    { name: "Alabama",
      value: "Alabama",
      id: "AL" },
    { name: "Alaska",
      value: "Alaska",
      id: "AK" },
    { name: "Arizona",
      value: "Arizona",
      id: "AZ" },
    { name: "Arkansas",
      value: "Arkansas",
      id: "AR" },
    { name: "California",
      value: "California",
      id: "CA" },
    { name: "Colorado",
      value: "Colorado",
      id: "CO" },
    { name: "Connecticut",
      value: "Connecticut",
      id: "CT" },
    { name: "Delaware",
      value: "Delaware",
      id: "DE" },
    { name: "Florida",
      value: "Florida",
      id: "FL" },
    { name: "Georgia",
      value: "Georgia",
      id: "GA" },
    { name: "Hawaii",
      value: "Hawaii",
      id: "HI" },
    { name: "Idaho",
      value: "Idaho",
      id: "ID" },
    { name: "Illinois",
      value: "Illinois",
      id: "IL" },
    { name: "Indiana",
      value: "Indiana",
      id: "IN" },
    { name: "Iowa",
      value: "Iowa",
      id: "IA" },
    { name: "Kansas",
      value: "Kansas",
      id: "KS" },
    { name: "Kentucky",
      value: "Kentucky",
      id: "KY" },
    { name: "Louisiana",
      value: "Louisiana",
      id: "LA" },
    { name: "Maine",
      value: "Maine",
      id: "ME" },
    { name: "Maryland",
      value: "Maryland",
      id: "MD" },
    { name: "Massachusetts",
      value: "Massachusetts",
      id: "MA" },
    { name: "Michigan",
      value: "Michigan",
      id: "MI" },
    { name: "Minnesota",
      value: "Minnesota",
      id: "MN" },
    { name: "Mississippi",
      value: "Mississippi",
      id: "MS" },
    { name: "Missouri",
      value: "Missouri",
      id: "MO" },
    { name: "Montana",
      value: "Montana",
      id: "MT" },
    { name: "Nebraska",
      value: "Nebraska",
      id: "NE" },
    { name: "Nevada",
      value: "Nevada",
      id: "NV" },
    { name: "New Hampshire",
      value: "New Hampshire",
      id: "NH" },
    { name: "New Jersey",
      value: "New Jersey",
      id: "NJ" },
    { name: "New Mexico",
      value: "New Mexico",
      id: "NM" },
    { name: "New York",
      value: "New York",
      id: "NY" },
    { name: "North Carolina",
      value: "North Carolina",
      id: "NC" },
    { name: "North Dakota",
      value: "North Dakota",
      id: "ND" },
    { name: "Ohio",
      value: "Ohio",
      id: "OH" },
    { name: "Oklahoma",
      value: "Oklahoma",
      id: "OK" },
    { name: "Oregon",
      value: "Oregon",
      id: "OR" },
    { name: "Pennsylvania",
      value: "Pennsylvania",
      id: "PA" },
    { name: "Rhode Island",
      value: "Rhode Island",
      id: "RI" },
    { name: "South Carolina",
      value: "South Carolina",
      id: "SC" },
    { name: "South Dakota",
      value: "South Dakota",
      id: "SD" },
    { name: "Tennessee",
      value: "Tennessee",
      id: "TN" },
    { name: "Texas",
      value: "Texas",
      id: "TX" },
    { name: "Utah",
      value: "Utah",
      id: "UT" },
    { name: "Vermont",
      value: "Vermont",
      id: "VT" },
    { name: "Virginia",
      value: "Virginia",
      id: "VA" },
    { name: "Washington",
      value: "Washington",
      id: "WA" },
    { name: "West Virginia",
      value: "West Virginia",
      id: "WV" },
    { name: "Wisconsin",
      value: "Wisconsin",
      id: "WI" },
    { name: "Wyoming",
      value: "Wyoming",
      id: "WY" },
  ],
  mx: [
    { id: 1,
      name: "Aguascalientes",
      value: "Aguascalientes" },
    { id: 2,
      name: "Baja California",
      value: "Baja California" },
    { id: 3,
      name: "Baja California Sur",
      value: "Baja California Sur" },
    { id: 4,
      name: "Campeche",
      value: "Campeche" },
    { id: 5,
      name: "Coahuila",
      value: "Coahuila" },
    { id: 6,
      name: "Colima",
      value: "Colima" },
    { id: 7,
      name: "Chiapas",
      value: "Chiapas" },
    { id: 8,
      name: "Chihuahua",
      value: "Chihuahua" },
    { id: 9,
      name: "Distrito Federal",
      value: "Distrito Federal" },
    { id: 10,
      name: "Durango",
      value: "Durango" },
    { id: 11,
      name: "Guanajuato",
      value: "Guanajuato" },
    { id: 12,
      name: "Guerrero",
      value: "Guerrero" },
    { id: 13,
      name: "Hidalgo",
      value: "Hidalgo" },
    { id: 14,
      name: "Jalisco",
      value: "Jalisco" },
    { id: 15,
      name: "México",
      value: "México" },
    { id: 16,
      name: "Michoacán",
      value: "Michoacán" },
    { id: 17,
      name: "Morelos",
      value: "Morelos" },
    { id: 18,
      name: "Nayarit",
      value: "Nayarit" },
    { id: 19,
      name: "Nuevo León",
      value: "Nuevo León" },
    { id: 20,
      name: "Oaxaca",
      value: "Oaxaca" },
    { id: 21,
      name: "Puebla",
      value: "Puebla" },
    { id: 22,
      name: "Querétaro",
      value: "Querétaro" },
    { id: 23,
      name: "Quintana Roo",
      value: "Quintana Roo" },
    { id: 24,
      name: "San Luis Potosí",
      value: "San Luis Potosí" },
    { id: 25,
      name: "Sinaloa",
      value: "Sinaloa" },
    { id: 26,
      name: "Sonora",
      value: "Sonora" },
    { id: 27,
      name: "Tabasco",
      value: "Tabasco" },
    { id: 28,
      name: "Tamaulipas",
      value: "Tamaulipas" },
    { id: 29,
      name: "Tlaxcala",
      value: "Tlaxcala" },
    { id: 30,
      name: "Veracruz",
      value: "Veracruz" },
    { id: 31,
      name: "Yucatán",
      value: "Yucatán" },
    { id: 32,
      name: "Zacatecas",
      value: "Zacatecas" },
  ],
  sv: [
    { id: 1,
      name: "Ahuachapan",
      value: "Ahuachapan" },
    { id: 2,
      name: "Cabanas",
      value: "Cabanas" },
    { id: 3,
      name: "Chalatenango",
      value: "Chalatenango" },
    { id: 4,
      name: "Cuscatlan",
      value: "Cuscatlan" },
    { id: 5,
      name: "La Libertad",
      value: "La Libertad" },
    { id: 6,
      name: "La Paz",
      value: "La Paz" },
    { id: 7,
      name: "La Union",
      value: "La Union" },
    { id: 8,
      name: "Morazan",
      value: "Morazan" },
    { id: 9,
      name: "San Miguel",
      value: "San Miguel" },
    { id: 10,
      name: "San Salvador",
      value: "San Salvador" },
    { id: 11,
      name: "Santa Ana",
      value: "Santa Ana" },
    { id: 12,
      name: "San Vicente",
      value: "San Vicente" },
    { id: 13,
      name: "Sonsonate",
      value: "Sonsonate" },
    { id: 14,
      name: "Usulutan",
      value: "Usulutan" },
  ],
  hn: [
    { id: 1,
      name: "Atlantida",
      value: "Atlantida" },
    { id: 2,
      name: "Choluteca",
      value: "Choluteca" },
    { id: 3,
      name: "Colon",
      value: "Colon" },
    { id: 4,
      name: "Comayagua",
      value: "Comayagua" },
    { id: 5,
      name: "Copan",
      value: "Copan" },
    { id: 6,
      name: "Cortes",
      value: "Cortes" },
    { id: 7,
      name: "El Paraiso",
      value: "El Paraiso" },
    { id: 8,
      name: "Francisco Morazan",
      value: "Francisco Morazan" },
    { id: 9,
      name: "Gracias a Dios",
      value: "Gracias a Dios" },
    { id: 10,
      name: "Intibuca",
      value: "Intibuca" },
    { id: 11,
      name: "Islas de la Bahia",
      value: "Islas de la Bahia" },
    { id: 12,
      name: "La Paz",
      value: "La Paz" },
    { id: 13,
      name: "Lempira",
      value: "Lempira" },
    { id: 14,
      name: "Ocotepeque",
      value: "Ocotepeque" },
    { id: 15,
      name: "Olancho",
      value: "Olancho" },
    { id: 16,
      name: "Santa Barbara",
      value: "Santa Barbara" },
    { id: 17,
      name: "Valle",
      value: "Valle" },
    { id: 18,
      name: "Yoro",
      value: "Yoro" },
  ],
  gt: [
    { id: 1,
      name: "Alta Verapaz",
      value: "Alta Verapaz" },
    { id: 2,
      name: "Baja Verapaz",
      value: "Baja Verapaz" },
    { id: 3,
      name: "Chimaltenango",
      value: "Chimaltenango" },
    { id: 4,
      name: "Chiquimula",
      value: "Chiquimula" },
    { id: 5,
      name: "El Progreso",
      value: "El Progreso" },
    { id: 6,
      name: "Escuintla",
      value: "Escuintla" },
    { id: 7,
      name: "Guatemala",
      value: "Guatemala" },
    { id: 8,
      name: "Huehuetenango",
      value: "Huehuetenango" },
    { id: 9,
      name: "Izabal",
      value: "Izabal" },
    { id: 10,
      name: "Jalapa",
      value: "Jalapa" },
    { id: 11,
      name: "Jutiapa",
      value: "Jutiapa" },
    { id: 12,
      name: "Peten",
      value: "Peten" },
    { id: 13,
      name: "Quetzaltenango",
      value: "Quetzaltenango" },
    { id: 14,
      name: "Quiche",
      value: "Quiche" },
    { id: 15,
      name: "Retalhuleu",
      value: "Retalhuleu" },
    { id: 16,
      name: "Sacatepequez",
      value: "Sacatepequez" },
    { id: 17,
      name: "San Marcos",
      value: "San Marcos" },
    { id: 18,
      name: "Santa Rosa",
      value: "Santa Rosa" },
    { id: 19,
      name: "Solola",
      value: "Solola" },
    { id: 20,
      name: "Suchitepequez",
      value: "Suchitepequez" },
    { id: 21,
      name: "Totonicapan",
      value: "Totonicapan" },
    { id: 22,
      name: "Zacapa",
      value: "Zacapa" },
  ],

  ni: [
    { id:1,
      name:"Atlantico Norte",
      value:"Atlantico Norte" },
    { id:2,
      name:"Atlantico Sur",
      value:"Atlantico Sur" },
    { id:3,
      name:"Boaco",
      value:"Boaco" },
    { id:4,
      name:"Carazo",
      value:"Carazo" },
    { id:5,
      name:"Chinandega",
      value:"Chinandega" },
    { id:6,
      name:"Chontales",
      value:"Chontales" },
    { id:7,
      name:"Esteli",
      value:"Esteli" },
    { id:8,
      name:"Granada",
      value:"Granada" },
    { id:9,
      name:"Jinotega",
      value:"Jinotega" },
    { id:10,
      name:"Leon",
      value:"Leon" },
    { id:11,
      name:"Madriz",
      value:"Madriz" },
    { id:12,
      name:"Managua",
      value:"Managua" },
    { id:13,
      name:"Masaya",
      value:"Masaya" },
    { id:14,
      name:"Matagalpa",
      value:"Matagalpa" },
    { id:15,
      name:"Nueva Segovia",
      value:"Nueva Segovia" },
    { id:16,
      name:"Rio San Juan",
      value:"Rio San Juan" },
    { id:17,
      name:"Rivas",
      value:"Rivas" },
  ],
};

export default states;
