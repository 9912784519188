// @styles
import styles from "./HeaderMobile.module.scss";

// @components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines } from "@fortawesome/free-solid-svg-icons";

// @modules
import React, { useState } from "react";
import HeaderModal from "../HeaderModal/HeaderModal";
import HeaderLang from "../HeaderLang/HeaderLang";
// import { useTranslation } from "react-i18next";
// import { useCrediverso } from "../../../../provider";
// import { SeparatorIcon } from "../../../../assets";
// import { useLocation } from "react-router-dom";
// import HeaderMyProfile from "../HeaderMyProfile/HeaderMyProfile";
// import { useSetURL } from "../../hooks";

export default function HeaderMobile() {
  const [show, setShow] = useState(false);
  // const { crediversoState } = useCrediverso();
  // const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const setURL = useSetURL();
  // const location = useLocation();
  // const currentPath = location.pathname.replace(/\//g, "");
  return (
    <div className={ styles.container }>
      {/* {!crediversoState.user.id ? (
        <a
          className={ styles.login }
          href={ `${setURL("login", true)}?from=${currentPath}` }
        >
          {t("header.login")}
        </a>
      ) : (
        <HeaderMyProfile />
      )} */}
      {/* <img alt="" src={ SeparatorIcon } /> */}
      <HeaderLang short />
      <button className={ styles.toggleModal } onClick={ handleShow }>
        <FontAwesomeIcon className={ styles.icon } icon={ faGripLines } />
      </button>
      <HeaderModal handleClose={ handleClose } show={ show } />
    </div>
  );
}
