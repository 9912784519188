import { mask as masker, unMask } from "remask";
import countries from "./lists/countries";

export const maskAmount = amount => {
  const originalValue = unMask(amount);
  const maskedValue = masker(originalValue, ["99", "99.99", "999.99", "9999.99", "99999.99"]);

  return maskedValue;
};

export const getCountryCode = countryValue => (
  countries.find(country => country.value === countryValue).phoneCode
);
