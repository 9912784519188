// @styles
import "./InputNumber.scss";
// @modules
import React, { useState } from "react";
import Control from "react-bootstrap/FormControl";
import PropTypes from "prop-types";
import { mask as masker, unMask } from "remask";

const InputZipCode = ({
  label = null,
  name = null,
  ...props
}) => {


  const handleChangeZip = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = masker(originalValue, ["99999"]);
    setValue(maskedValue);
  };

  const [value, setValue] = useState("");

  return (
    <Control
      name={ name }
      onChange={ handleChangeZip }
      type="text"
      value={ value }
      { ...props }
    />
  );
};

InputZipCode.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

export default InputZipCode;
