import React from "react";
import ScoreBar from "../../credit-score-bar/ScoreBar";
import styles from "./Notification.module.scss";
import { assessCreditScore } from "../../../utils/creditScoreUtils";
import { useTranslation } from "react-i18next";

export const NotificationCreditScoreIncrease = ({ details }) => {
  const { t } = useTranslation();
  const oldRating = assessCreditScore(details.oldCreditScore);
  const newRating = assessCreditScore(details.newCreditScore);

  return (
    <div className={ styles.notificationContentContainer }>
      <h3>Congrats!</h3>
      <h3>
        You got{" "}
        <span className={ styles.excellent }>{details.scoreVariance}</span>{" "}
        points!
      </h3>
      <h1 className={ styles[newRating] }>{details.newCreditScore}</h1>
      <ScoreBar score={ [details.oldCreditScore, details.newCreditScore] } />
      <p>
        Your credit score increased {details.scoreVariance} points to{" "}
        {details.newCreditScore} points!
      </p>
      <ul className={ styles.scoreChangeDetails }>
        <li>
          <b>Old credit score:</b> <span>{details.oldCreditScore}</span>
        </li>
        <li>
          <b>Old credit rating:</b> <span>{t("cch.rating." + oldRating)}</span>
        </li>
        <li>
          <b>New credit score:</b> <span>{details.newCreditScore}</span>
        </li>
        <li>
          <b>New Credit Rating:</b> <span>{t("cch.rating." + newRating)}</span>
        </li>
      </ul>
    </div>
  );
};
