// @modules
import React from "react";
import PropTypes from "prop-types";
import Control from "react-bootstrap/FormControl";
import Label from "react-bootstrap/FormLabel";

function Select({
  label = null,
  placeholder = null,
  name,
  onSelect = () => { },
  options,
  ...props
}) {
  const optionsJSX = options.map(currentOption => {
    return (
      <option key={ currentOption.id } value={ currentOption.value }>
        { currentOption.name }
      </option>
    );
  });

  const onChange = event => {
    event.stopPropagation();
    onSelect(event.target.value);
  };

  return (
    <>
      { label && <Label>{ label }</Label> }

      <Control
        as="select"
        className="form-control-lg"
        custom
        name={ name }
        onChange={ onChange }
        { ...props }
      >
        <option className="placeholder" hidden key={ "0" } value="" >{ placeholder }</option>
        {
          optionsJSX.map((option, index) => ({
            ...option,
            id: `${name}-${index}`,
            key: `${name}-${index}`,
          }))
        }
      </Control>
    </>
  );
}

Select.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onSelect: PropTypes.func,
  options: PropTypes.array.isRequired,
};

export default Select;
