// @modules
import React, { useState } from "react";
import Control from "react-bootstrap/FormControl";
import PropTypes from "prop-types";
import { mask as masker, unMask } from "remask";

const InputClabe = ({
  className,
  label = null,
  name = null,
  ...props
}) => {
  const [value, setValue] = useState("");

  const handleChangeClabe = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = masker(originalValue, ["9999-9999-9999-999999"]);
    setValue(maskedValue);
  };

  return (
    <Control
      className={ className }
      name={ name }
      onChange={ handleChangeClabe }
      type="text"
      value={ value }
      { ...props }
    />
  );
};

InputClabe.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
};

export default InputClabe;
