// @styles
import Styles from "./Stepper.module.scss";
// @modules
import PropTypes from "prop-types";
import React, { Children } from "react";

function Stepper({ children, step, className }) {
    return (
        <div className={`${className} ${Styles['main']}`}>
            {Children.map(children, (child, index) => {
                try {

                    let mainLength = children.length;
                        mainLength--;

                    let innerStep = (index + 1);

                    let checked = (step > innerStep ? true : false);
                    let active = (step == innerStep ? true : false);
                    let label = (child.props.label == "" ? innerStep : child.props.label);
                    let dots = (mainLength == index ? false : true);
                    let firstDots = (index === 0 ? false : true);

                    let image = child.props?.image;

                    return (
                        <>
                            { React.cloneElement(child,  {checked, active, label, dots, firstDots, image}) }
                        </>
                    )
                } catch(e) { /* ... */}
                
            })}
        </div>
    );
}

Stepper.propTypes = {
    step: PropTypes.number,
};

export default Stepper;
  