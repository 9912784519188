import { useEffect } from "react";
import styles  from "./RadioButtonQuestion.module.scss";

import { useTranslation } from "react-i18next";

const RadioButtonQuestion = ({question, questionEs, answers, isTranslated, type, number, onClick}) => {

  const { i18n } = useTranslation();

  return (
    <div className={styles["radio-questions"]}>
        <p className={styles["question"]}>{number}. {(i18n.language === 'en') || (i18n.language === 'en-US') ? question.en : question.es}</p>
        {answers.map(a => (
            <div key={a.answerNumber} id={a.answerNumber} className={styles["answer"]}>
                <label onClick={(e) => onClick({question: number, answer: a.answerNumber})}>
                  <input type="radio" id={a.answerNumber} name={type} value={a.answer_es}/>
                  <span class={styles["checkmark"]}></span>
                  {(i18n.language === 'en') || (i18n.language === 'en-US') ? a.answer : a.answer_es}
                </label>
            </div>
        ))}
    </div>
  )
}

export default RadioButtonQuestion