// @styles
import "./JoinAccountForm.scss";
// @modules
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { onSubmitHelper } from "@crediverso/crediframework/src/utils/form";
import {
  Button,
  Input,
  InputPhone,
} from "@crediverso/crediframework";

function JoinAccountForm({
  disableSubmit,
  onSubmit = () => {},
}) {
  const [translateText] = useTranslation("translations");
  const [isLoading, setIsLoading] = useState(disableSubmit);

  useEffect(() => {
    setIsLoading(disableSubmit);
  }, [disableSubmit]);

  const submit = submitEvent => {
    const formValues = onSubmitHelper(submitEvent);
    onSubmit(formValues);
  };

  return (
    <Form className="join-account-form" onSubmit={ submit }>
      <Input
        label={ translateText("form.label.email") }
        name="email"
        placeholder={ translateText("form.placeholder.email") }
        required
        type="email"
      />

      <Input
        label={ translateText("form.label.password") }
        name="password"
        placeholder={ translateText("form.placeholder.password") }
        required
        type="password"
      />

      <InputPhone
        label={ translateText("form.label.phone") }
        name="phone"
        required
      />

      <div className="join-account-form__submit-button">
        <Button
          disabled={ isLoading }
          type="submit"
        >
          {isLoading ? translateText("form.button.loading") : translateText("form.button.join") }
        </Button>
      </div>
    </Form>
  );
}

JoinAccountForm.propTypes = {
  disableSubmit: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
};

export default JoinAccountForm;
