const countries = [
  {
    id: "MX",
    name: "Mexico",
    value: "mx",
    currencyCode: "MXN",
    phoneCode: "+52",
  },
  {
    id: "USD",
    name: "USA",
    value: "us",
    currencyCode: "USD",
    phoneCode: "+1",
  },
  {
    id: "HN",
    name: "Honduras",
    value: "hn",
    currencyCode: "HNL",
    phoneCode: "+504",
  },
  {
    id: "GT",
    name: "Guatemala",
    value: "gt",
    currencyCode: "GTM",
    phoneCode: "+502",
  },
  {
    id: "SV",
    name: "El Salvador",
    value: "sv",
    currencyCode: "USD",
    phoneCode: "+503",
  },
  {
    id: "NI",
    name: "Nicaragua",
    value: "ni",
    currencyCode: "NIC",
    phoneCode: "+505",
  },
];

export default countries;
