import {
  NotificationCardOverLimit,
  NotificationPersonalInfoChange,
  NotificationOthers,
  NotificationOpenAccount,
  NotificationNegativeMark,
  NotificationLatePayment,
  NotificationHighCreditUsage,
  NotificationHardInquiry,
  NotificationCreditScoreIncrease,
  NotificationCreditScoreDecrease,
  NotificationCreditLimitIncrease,
} from "./types";

import { NOTIFICATION_TYPE } from "./constants";

function NotificationContent({ type, details }) {
  switch (type) {
    case NOTIFICATION_TYPE.creditScoreIncrease:
      return <NotificationCreditScoreIncrease details={ details } />;
    case NOTIFICATION_TYPE.creditScoreDecrease:
      return <NotificationCreditScoreDecrease details={ details } />;
    case NOTIFICATION_TYPE.creditLimitIncrease:
      return <NotificationCreditLimitIncrease details={ details } />;
    case NOTIFICATION_TYPE.cardOverLimit:
      return <NotificationCardOverLimit details={ details } />;
    case NOTIFICATION_TYPE.hardInquiry:
      return <NotificationHardInquiry details={ details } />;
    case NOTIFICATION_TYPE.highCreditUsage:
      return <NotificationHighCreditUsage details={ details } />;
    case NOTIFICATION_TYPE.latePayment:
      return <NotificationLatePayment details={ details } />;
    case NOTIFICATION_TYPE.negativeMarks:
      return <NotificationNegativeMark details={ details } />;
    case NOTIFICATION_TYPE.openAccount:
      return <NotificationOpenAccount details={ details } />;
    case NOTIFICATION_TYPE.NotificationOthers:
      return <NotificationOthers details={ details } />;
    case NOTIFICATION_TYPE.personalInfoChange:
      return <NotificationPersonalInfoChange details={ details } />;
    default:
      return <NotificationOthers details={ details } />;
  }
}

export default NotificationContent;
