import {
  IconIncreaseCredit,
  IconInfo,
  IconWarning,
  IconIncrease,
  IconDecrease,
} from "./assets";
import { NOTIFICATION_TYPE } from "./constants";

const NotificationIcon = ({ type }) => {
  switch (type) {
    case NOTIFICATION_TYPE.creditLimitIncrease:
      return <IconIncreaseCredit />;
    case NOTIFICATION_TYPE.cardOverLimit:
      return <IconWarning />;
    case NOTIFICATION_TYPE.hardInquiry:
      return <IconWarning />;
    case NOTIFICATION_TYPE.highCreditUsage:
      return <IconWarning />;
    case NOTIFICATION_TYPE.latePayment:
      return <IconWarning />;
    case NOTIFICATION_TYPE.negativeMarks:
      return <IconWarning />;
    case NOTIFICATION_TYPE.openAccount:
      return <IconInfo />;
    case NOTIFICATION_TYPE.NotificationOthers:
      return <IconIncrease />;
    case NOTIFICATION_TYPE.personalInfoChange:
      return <IconInfo />;
    case NOTIFICATION_TYPE.creditScoreIncrease:
      return <IconIncrease />;
    case NOTIFICATION_TYPE.creditScoreDecrease:
      return <IconDecrease />;
    default:
      return <IconInfo />;
  }
};

export default NotificationIcon;
