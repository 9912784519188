import { useCallback, useMemo } from "react";
import { useCrediverso } from "../../provider";
import { useLang } from "../hooks";

export const useBaseUrl = () => {
  return useMemo(() => {
    let baseURL = "https://dev.raas.crediverso.com/";
    if (window.location.hostname === "uat.raas.crediverso.com")
      baseURL = "https://uat.raas.crediverso.com/";
    else if (window.location.hostname === "app.crediverso.com")
      baseURL = "https://app.crediverso.com/";
    return baseURL;
  }, []);
};

export const useSetURL = () => {
  const baseURL = useBaseUrl();
  const { computeURL } = useCrediverso();
  const { lang } = useLang();
  return useCallback(
    (path, isWordPress, newHome) => {
      return isWordPress
        ? computeURL(path, isWordPress, newHome)
        : `${baseURL}${path}${path.includes("?") ? "&" : "?"}lang=${lang}`;
    },
    [baseURL, computeURL, lang],
  );
};
