import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { unstable_batchedUpdates } from "react-dom";

const getTotalPages = (items, itemsPerPage) => Math.ceil(items / itemsPerPage);

export default function usePagination({ currentPage, items, itemsPerPage }) {
  const [endIndex, setEndIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [pages, setPages] = useState(1);

  useEffect(() => {
    const totalPages = getTotalPages(items, itemsPerPage);
    const newStartIndex = (currentPage - 1) * itemsPerPage;
    const newEndIndex = newStartIndex + itemsPerPage - 1;

    unstable_batchedUpdates(() => {
      setPages(totalPages);
      setEndIndex(newEndIndex);
      setStartIndex(newStartIndex);
    });

  }, [items, itemsPerPage, currentPage]);

  return {
    endIndex,
    pages,
    startIndex,
  };
}

usePagination.propTypes = {
  currentPage: PropTypes.number,
  items: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
};
