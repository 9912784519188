import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

const MainWizardContext = createContext({});  

const WizardContext = (props) => {
    const { t } = useTranslation();

    const [ currentStep, setCurrentStep ] = useState(0);
    const [ stepsLength, setStepsLength ] = useState(0);
    
    const [ nextDisabled, setNextDisabled ] = useState(false);
    const [ previousDisabled, setPreviousDisabled ] = useState(false);

    const [ nextButtonVisible, setNextButtonVisible ] = useState(true);
    const [ previousButtonVisible, setPreviousButtonVisible ] = useState(false);

    const [ isLoading, setIsLoading ] = useState(false);

    const [ stepperVisible, setProgressVisible ] = useState(true);

    const [ nextLabel, setNextLabel ] = useState(t('cch.mainCheckStepper.next'));
    const [ previousLabel, setPreviousLabel ] = useState(t('cch.mainCheckStepper.previous'));

    const [ infinite, setInfinite ] = useState(false);

    async function next(callback = () => {
        return true;
    }) {
        resetNavigationLabels();
        setPreviousButtonVisible(false);
        
        let innerCallback = await callback();
        
        if (innerCallback) {
            if (infinite) {
                if (currentStep == stepsLength) {
                    setCurrentStep(0);
                } else {
                    setCurrentStep(currentStep + 1);
                }
            } else {
                if (currentStep < stepsLength) {
                    setCurrentStep(currentStep + 1);
                }
            }
        }
        
        return true;
    }

    function previous() {
        resetNavigationLabels();
        setPreviousButtonVisible(false);

        if (infinite) {
            if (currentStep == 0 ) {
                setCurrentStep(stepsLength);
            }
        } else {
            if (currentStep >= 1) {
                setCurrentStep(currentStep - 1);
            }
        }
    }

    function resetNavigationLabels() {
        setNextLabel(t('cch.mainCheckStepper.next'));
        setPreviousLabel(t('cch.mainCheckStepper.previous'));
    }

    useEffect(() => {
        if (nextLabel == "next" || nextLabel == "siguiente") {
            setNextLabel(t('cch.mainCheckStepper.next'));
        }

        if (previousLabel == "previous" || previousLabel == "anterior") {
            setPreviousLabel(t('cch.mainCheckStepper.previous'));
        }
    }, [t]);

    return (
        <MainWizardContext.Provider value={{
                currentStep,
                setCurrentStep,

                stepsLength,

                next,
                previous,
                setStepsLength,
            
                nextDisabled,
                nextLabel,
                //trySubmit,
                nextButtonVisible,
                setNextButtonVisible,
                setNextLabel,
                setNextDisabled,
                
                previousDisabled,
                previousLabel,
                previousButtonVisible,
                setPreviousButtonVisible,
                setPreviousLabel,
                setPreviousDisabled,
                
                infinite, 
                setInfinite,

                stepperVisible,
                setProgressVisible
            }}
            
            {...props}
        >
        </MainWizardContext.Provider> 
    );
}

export {
    MainWizardContext,
    WizardContext
};