import React, { useEffect } from "react";

const getBaseUrl = () => {
  let baseURL = "https://www3-dev.crediverso.com";
  if (window.location.hostname.includes("uat.raas.crediverso.com"))
    baseURL = "https://www3-uat.crediverso.com";
  else if (window.location.hostname.includes("app.crediverso.com"))
    baseURL = "https://www3.crediverso.com";

  return baseURL;
};


function Login() {

  useEffect(() => {
    window.location.href = `${getBaseUrl()}/en/login/?from=raas`;
  }, []);

  return (
    <div>
      <h2>Redirecting to Login...</h2>
    </div>
  );
}

export default Login;
