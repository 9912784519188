import React from "react";
import { IconCircleArrow } from "../assets";

function NotificationCardOverLimit() {
  return (
    <div style={ {
      display: "flex",
      marginTop: "3rem",
      alignItems: "center",
      flexDirection: "column",

    } }>
      <div style={ {
        height: "6rem",
      } }>
        <IconCircleArrow />
      </div>
      <h6>Account Info: CAPITAL ONE BANK USA</h6>
      <h6 style={ {
      } }>Your Credit Usage: 77%</h6>
      <h6>Your Credit Limit: $2000</h6>
      <h6>Your Current Balance: $1,540 </h6>
      <h6>Balance Date: Aug 12, 21</h6>
    </div>
  );
}

export { NotificationCardOverLimit };
