export const NOTIFICATION_TYPE = {
  cardOverLimit: "cardOverLimit",
  creditLimitIncrease: "creditLimitIncrease",
  creditScoreDecrease: "creditScoreDecrease",
  creditScoreIncrease: "creditScoreIncrease",
  hardInquiry: "hardInquiry",
  highCreditUsage: "highCreditUsage",
  increased: "increased",
  latePayment: "latePayment",
  negativeMarks: "negativeMarks",
  openAccount: "openAccount",
  personalInfoChange: "personalInfoChange",
};

export const NOTIFICATION_SECTION = {
  all: "ALL",
  creditChecks: "Credit Checks",
  creditCards: "Credit Cards",
  remittances: "Remittances",
  loans: "Loans",
};
