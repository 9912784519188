// @styles
import styles from "./HeaderIpad.module.scss";

// @components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines } from "@fortawesome/free-solid-svg-icons";

// @modules
import React, { useState } from "react";
import HeaderModal from "../HeaderModal/HeaderModal";
import HeaderLang from "../HeaderLang/HeaderLang";
import { useTranslation } from "react-i18next";
import { useCrediverso } from "../../../../provider";
import { SeparatorIcon } from "../../../../assets";
import SearchBar from "../HeaderSearchBar/SearchBar";
import HeaderMyProfile from "../HeaderMyProfile/HeaderMyProfile";
import { useSetURL } from "../../hooks";
import { useLocation } from "react-router-dom";

export default function HeaderIpad() {
  const [show, setShow] = useState(false);
  const { crediversoState } = useCrediverso();
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const location = useLocation();
  const currentPath = location.pathname.replace(/\//g, "");
  const setURL = useSetURL();

  return (
    <div className={ styles.container }>
      <div className={ styles.searchBar }>
        <SearchBar />
      </div>
      {!crediversoState.user.id ? (
        <a
          className={ styles.login }
          href={ `${setURL("login", true)}?from=${currentPath}` }
        >
          {t("header.login")}
        </a>
      ) : (
        <HeaderMyProfile />
      )}
      <img alt="" src={ SeparatorIcon } />
      <HeaderLang short />
      <button className={ styles.toggleModal } onClick={ handleShow }>
        <FontAwesomeIcon icon={ faGripLines } />
      </button>
      <HeaderModal handleClose={ handleClose } show={ show } />
    </div>
  );
}
