/* eslint-disable jsx-a11y/anchor-is-valid */
// @modules
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSetURL } from "../../hooks";
import HeaderDropdown from "../HeaderDropdown";

// @styles
import styles from "./MenuLeft.module.scss";

const MenuLeft = () => {
  const { t } = useTranslation();
  const setURL = useSetURL();

  const compareItems = useMemo(
    () => [
      {
        label: t("header.compareCreditCards"),
        href: setURL("creditcards", true),
      },
      // { label: t("header.compareLoans"),
      //   href: setURL("loans", true) },
    ],
    [setURL, t]
  );

  return (
    <ul className={styles["header-menu-left"]}>
      <li>
        <Link to="/credit_checks" className={styles["menu-link"]}>
            {t("header.checkMyCredit")}
        </Link>
      </li>
      <li>
        <a
          className={styles["menu-link"]}
          href={setURL("blog", true, true)}
        >
          {t("header.blog")}
        </a>
      </li>
      {/* <li>
        <a className={styles["menu-link"]} href={setURL("remittance", true)}>
          {t("header.sendMoney")}
        </a>
      </li>

      <li>
        <a className={styles["menu-link"]} href={setURL("blog", true)}>
          {t("header.blog")}
        </a>
      </li>
      <li className="d-none">
        <HeaderDropdown items={compareItems} label={t("header.compare")} />
      </li> */}
    </ul>
  );
};

export default MenuLeft;
