// @styles
import "./ScoreBar.scss";
// @modules
import React from "react";
import PropTypes from "prop-types";

// @assets
import calculateScorePosition from "./calculateScorePosition";

const ScoreBar = ({ score = [0] }) => {
  return (
    <div className="score__bar">
      <div className="bar__container">
        <div className="red__bar"></div>
        <div className="yellow__bar"></div>
        <div className="blue__bar"></div>
        <div className="green__bar"></div>
      </div>
      {score.map(e => (
        <div className="position__container" key={ e }>
          <div className="position" style={ calculateScorePosition(e) }></div>
        </div>
      ))}
      <div className="num__container">
        <div className="red__bar">300</div>
        <div className="yellow__bar">600</div>
        <div className="blue__bar">660</div>
        <div className="green__bar">780</div>
        <div className="green__bar_middle">850</div>
      </div>
    </div>
  );
};

ScoreBar.propTypes = {
  score: PropTypes.arrayOf(PropTypes.number),
};

export default ScoreBar;
