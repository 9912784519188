// @modules
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCrediverso } from "../../../provider";
import { useSetURL } from "../hooks";
// @styles
import styles from "./FooterMobile.module.scss";

// @components
import Accordion from "react-bootstrap/Accordion";
import ContactUs from "../contact-us/ContactUs";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

const FooterMobile = () => {
  const [activeKey, setActiveKey] = useState(null);
  const { t } = useTranslation();
  const { computeURL } = useCrediverso();

  const handleToggle = useCallback(
    eventKey => () => {
      if (activeKey === eventKey)
        setActiveKey(null);
      else
        setActiveKey(eventKey);
    },
    [activeKey],
  );

  const isActive = useCallback(eventKey => eventKey === activeKey, [
    activeKey,
  ]);

  const setURL = useSetURL();

  return (
    <div>
      <ContactUs />
      <Accordion activeKey={ activeKey } className={ styles.accordion }>
        <div className={ styles.accordionStep }>
          <div className={ styles.header }>
            <p className={ styles.title }>{t("footer.upper.products.title")}</p>
            <Accordion.Toggle
              as={ Button }
              className={ styles.toggle }
              eventKey={ "0" }
              onClick={ handleToggle("0") }
              variant="link"
            >
              <FontAwesomeIcon icon={ isActive("0") ? faAngleUp : faAngleDown } />
            </Accordion.Toggle>
          </div>
          <Accordion.Collapse eventKey="0">
            <ul className={ styles.body }>
              <li>
                <a className={ styles.link } href={ setURL("remittance", true) }>
                  {t("footer.upper.products.sendmoney")}
                </a>
              </li>
              <li>
                <a
                  className={ styles.link }
                  href={ setURL(
                    localStorage.getItem("userToken")
                      ? "credit_checks/dashboard"
                      : "credit_checks",
                    false,
                  ) }
                >
                  {t("footer.upper.products.creditchecks")}
                </a>
              </li>
              <li>
                <a className={ styles.link } href={ setURL("creditcards", true) }>
                  {t("footer.upper.products.creditcards")}
                </a>
              </li>
              {/* <li style={ { display: "none" } }>
                <a className={ styles.link } href={ setURL("loans", true) }>
                  {t("footer.upper.products.loans")}
                </a>
              </li> */}
            </ul>
          </Accordion.Collapse>
        </div>

        <div className={ styles.accordionStep }>
          <div className={ styles.header }>
            <p className={ styles.title }>{t("footer.upper.community.title")}</p>
            <Accordion.Toggle
              as={ Button }
              className={ styles.toggle }
              eventKey={ "1" }
              onClick={ handleToggle("1") }
              variant="link"
            >
              <FontAwesomeIcon icon={ isActive("1") ? faAngleUp : faAngleDown } />
            </Accordion.Toggle>
          </div>
          <Accordion.Collapse eventKey="1">
            <ul className={ styles.body }>
              <li>
              {/* eslint-disable-next-line */}
                <a className={ styles.link } 
                // href={ setURL("media", false) }
                >
                  {t("footer.upper.company.newsroom")}
                </a>
              </li>
              <li>
                <a className={ styles.link } href={ setURL("blog", true, true) }>
                  {t("footer.upper.community.blog")}
                </a>
              </li>
            </ul>
          </Accordion.Collapse>
        </div>

        <div className={ styles.accordionStep }>
          <div className={ styles.header }>
            <p className={ styles.title }>{t("footer.upper.company.title")}</p>
            <Accordion.Toggle
              as={ Button }
              className={ styles.toggle }
              eventKey={ "2" }
              onClick={ handleToggle("2") }
              variant="link"
            >
              <FontAwesomeIcon icon={ isActive("2") ? faAngleUp : faAngleDown } />
            </Accordion.Toggle>
          </div>
          <Accordion.Collapse eventKey="2">
            <ul className={ styles.body }>
              <li>
                <a className={ styles.link } href={ setURL("about-us", true) }>
                  {t("footer.upper.company.about")}
                </a>
              </li>
              <li>
                <a className={ styles.link } href={ setURL("faqs", true) }>
                  {t("footer.upper.company.howitworks")}
                </a>
              </li>
              <li>
                <a
                  className={ styles.link }
                  href={ setURL("product-update", true) }
                >
                  {t("footer.upper.company.product")}
                </a>
              </li>
            </ul>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </div>
  );
};

export default FooterMobile;
