// @modules
import React from "react";
import Select from "../select";
import states from "../../utils/lists/states";

export default function SelectState({ country, ...props }) {
  const selCountry = states[country];
  return (
    <Select options={ selCountry } { ...props } />
  );
}
