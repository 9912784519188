// @assets
import {
  faSortAmountDown,
  faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";
// @styles
import "./SortIconButton.scss";
// @modules
import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SortIconButton({
  onClickDecreasing = () => {},
  onClickIncreasing = () => {},
}) {
  const [decreasingIsVisible, setDecreasingIsVisible] = useState(true);

  const onClickDecreasingIcon = () => {
    setDecreasingIsVisible(true);
    onClickIncreasing();
  };

  const onClickIncreasingIcon = () => {
    setDecreasingIsVisible(false);
    onClickDecreasing();
  };

  return (
    <div className="sort-icon-button">
      <FontAwesomeIcon
        className={ `sort-icon-button__button ${decreasingIsVisible ? "sort-icon-button--hidden" : ""}` }
        icon={ faSortAmountUp }
        onClick={ onClickDecreasingIcon }
      />

      <FontAwesomeIcon
        className={ `sort-icon-button__button ${!decreasingIsVisible ? "sort-icon-button--hidden" : ""}` }
        icon={ faSortAmountDown }
        onClick={ onClickIncreasingIcon }
      />
    </div>
  );
}

SortIconButton.propTypes = {
  onClickDecreasing: PropTypes.func,
  onClickIncreasing: PropTypes.func,
};

export default SortIconButton;
