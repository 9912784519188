// @modules
import React from "react";
import PropTypes from "prop-types";
import Select from "../select";
import countriesList from "./countries";

function CountryDropdown({
  countryCode = null,
  filter,
  onSelect = () => { },
  ...props
}) {
  const updateCountry = selected => {
    const newCountry = selected.toLowerCase();
    onSelect(newCountry);
  };

  const countries = filter ? countriesList.filter(country => country.value === filter) : countriesList;

  return (
    <Select onSelect={ updateCountry } options={ countries } style={ { textTransform: "initial" } } { ...props } />
  );
}

CountryDropdown.propTypes = {
  countryCode: PropTypes.string,
  onSelect: PropTypes.func,
};

export default CountryDropdown;
