// @modules
import { useState, useRef } from "react";
import PropTypes from "prop-types";

function useAccordionMemory({
  currentKey,
  nextInfo,
}) {
  const nextInfoMove = useRef(nextInfo);
  const [keys, setKeys] = useState({
    currentKey,
    previousKey: null,
  });

  const moveForward = key => {
    if (key === currentKey) {
      return setKeys({
        ...keys,
        currentKey: key,
        previousKey: null,
      });
    }

    return setKeys({
      ...keys,
      currentKey: key,
      previousKey: keys.currentKey,
    });
  };

  const moveNext = () => {
    if (keys.previousKey) {
      return setKeys({
        ...keys,
        currentKey: keys.previousKey,
        previousKey: null,
      });
    }

    setKeys({
      ...keys,
      currentKey: nextInfoMove.current[keys.currentKey],
      previousKey: null,
    });
  };

  return {
    ...keys,
    moveForward,
    moveNext,
  };
}

useAccordionMemory.propTypes = {
  currentKey: PropTypes.string.isRequired,
  nextInfo: PropTypes.object.isRequired,
};

export default useAccordionMemory;
