import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

const en = "en";
const es = "es";

// Returns current language of the website and related methods

const useLang = () => {
  const [lang, setLang] = useState(en);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language.includes(es))
      setLang(es);
    else
      setLang(en);
  }, [i18n.language]);

  const changeLang = useCallback(
    newLang => {
      i18n.changeLanguage(newLang);
    },
    [i18n],
  );

  const switchLang = useCallback(() => {
    if (lang === en)
      changeLang(es);
    else if (lang === es)
      changeLang(en);
  }, [changeLang, lang]);

  const getCurrentLang = useCallback(() => {
    return lang;
  }, [lang]);

  return { lang,
    changeLang,
    switchLang,
    getCurrentLang };
};

export default useLang;
