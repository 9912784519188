import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./langs/en.json";
import es from "./langs/es.json";

i18n.use(LanguageDetector).init({
  detection: {
    lookupSessionStorage: "i18nextLng",
  },
  debug: false,
  defaultNS: "translations",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
  // lng: navigator.language || navigator.userLanguage,
  ns: ["translations"],
  react: {
    useSuspense: true,
  },
  resources: {
    en,
    es,
  },
});

export default i18n;
