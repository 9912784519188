// @components
import Modal from "../modal";
import { CodeConfirmation } from "./CodeConfirmation";

function ModalSmsConfirmation({ visible, onSubmit }) {

  return (
    <Modal visible={ visible } visibleCloseButton={ false }>
      <CodeConfirmation
        onSubmit={ onSubmit }
      />
    </Modal>
  );
}

export default ModalSmsConfirmation;
