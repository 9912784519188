import axios from "axios";

const getBaseUrl = () => {
  let baseURL = "https://api-dev.crediverso.com";
  if (window.location.hostname.includes("uat.raas.crediverso.com"))
    baseURL = "https://api-uat.crediverso.com";
  else if (window.location.hostname.includes("app.crediverso.com"))
    baseURL = "https://api.crediverso.com";

  return baseURL;
};


const axiosConfiguration = axios.create({
  baseURL: `${getBaseUrl()}/v1/`,
  responseType: "json",
  timeout: 25000,
});

export default axiosConfiguration;
