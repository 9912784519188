// @modules
import React, { useCallback, useEffect, useState } from "react";
import { useNotifications } from "../../notifications/context";

// @styles
import style from "./Notification.module.scss";

// @components
import { TipsBox, Dot, DeviderOrange, Devider } from "./components";
import { Button } from "react-bootstrap";
import NotificationIcon from "./NotificationIcon";
import NotificationContent from "./NotificationContent";

// @assets
import { NOTIFICATION_TYPE } from "./constants";

function SandBox() {
  const payload = {};
  return (
    <div
      style={ {
        display: "flex",
        height: "1500px",
        paddingTop: "200px",
        justifyContent: "center",
      } }
    >
      <Notification
        date="Sep 23, 2021"
        description="Your credit score decreased 50 points to 750 points."
        impact="Medium Impact"
        section="Credit Checks"
        title={ "Credit Score Increased" }
        type={ NOTIFICATION_TYPE.scoreIncreaseUsage }
      />

      <Notification
        content={ payload }
        date="Sep 23, 2021"
        description="Your credit utilization is high. Using less than 30% of your availible credit can improve your score."
        impact="Medium Impact"
        section="Credit Checks"
        tipDescription="Credit Usage contribute to 20% of VantageScore. Experts recommend maintaining credit usage below 30% to improve your credit score"
        title={ "High Credit Usage" }
        type={ NOTIFICATION_TYPE.highCreditUsage }
      />
      <div
        style={ {
          width: "10px",
        } }
      />
      <Notification
        content={ payload }
        date="Sep 23, 2021"
        description="Your credit score decreased 50 points to 750 points."
        impact="Medium Impact"
        section="Credit Checks"
        tipDescription="Credit Usage contribute to 20% of VantageScore.Experts recommend maintaining credit usage below 30% to improve your credit score"
        title={ "Card Over Limit" }
        type={ NOTIFICATION_TYPE.cardOverLimit }
      />
    </div>
  );
}

export const Notification = React.memo(({ initiallyFull, data }) => {
  const [isFullView, setIsFullView] = useState(false);
  const { markRead, toggleRead } = useNotifications();
  useEffect(() => {
    if (initiallyFull)
      setIsFullView(true);
  }, [initiallyFull]);

  const handleToggleExpand = useCallback(() => {
    if (!isFullView)
      markRead(data.id);
    setIsFullView(prevState => !prevState);
  }, [data.id, isFullView, markRead]);

  const handleToggleRead = useCallback(() => {
    toggleRead(data.id);
  }, [data.id, toggleRead]);

  return (
    <div
      className={ `${style.notification__container} ${
        isFullView ? style.isFullView : ""
      }` }
      style={ data.read ? {} : { border: "1px solid coral" } }
    >
      <div className={ style.notification_inner_container }>
        <div className={ style.icon_container }>
          <NotificationIcon type={ data.type } />
        </div>
        <div className={ style.notification_description_container }>
          <h6 className={ style.titleText }>{data.title}</h6>
          {isFullView && <DeviderOrange />}
          <div>
            <h6 className={ style.descriptionText }>{data.description}</h6>
          </div>
          <div className={ style.descriptionContentContainer }>
            <h6 className={ style.grayText }>{data.date}</h6>
            <Dot />
            <h6 className={ style.grayText }>{data.section}</h6>
            <Dot />
            <h6 className={ style.warningText }>{data.impact} Impact</h6>
            <Dot />
            <Button
              className={ style.toggle }
              onClick={ handleToggleExpand }
              variant="link"
            >
              {isFullView ? "Short View" : "Full View"}
            </Button>
            <Dot />
            <Button
              className={ style.toggle }
              onClick={ handleToggleRead }
              variant="link"
            >
              {data.read ? "Mark Unread" : "Mark Read"}
            </Button>
          </div>
        </div>
      </div>
      {isFullView && (
        <div className={ style.notification_content_container }>
          <Devider />
          <NotificationContent details={ data.details } type={ data.type } />
          {data.tipDescription && (
            <TipsBox tipDescription={ data.tipDescription } />
          )}
        </div>
      )}
    </div>
  );
});

export default SandBox;
