// @modules
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import JoinAccountForm from "../../containers/join-account-form";
import Row from "react-bootstrap/Row";
import { useHistory } from "react-router-dom";
import {
  createJob,
  cancelJob,
  setInputJob,
} from "../../common/api";

const createAccountFromFormValues = formValues => {
  return {
    email: formValues.email,
    isExisting: true,
    password: formValues.password,
    phone: {
      countryCode: formValues.phoneCountryCode,
      number: formValues.phone,
    },
  };
};

function JoinAccount() {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const history = useHistory();

  const sendAccountInput = async(jobId, account) => {
    try {
      return await setInputJob("remitly", jobId, "account", account);
    }
    catch (error) {
      await cancelJob("remitly", jobId);
      // Todo: Replace this console.error with ui message.
      // console.error(`[Send Account Input]: ${error.toString()}`);
    }

    return null;
  };

  const submitAccountData = async accountFormValues => {
    try {
      setDisableSubmit(true);
      const { data: jobData } = await createJob("remitly");

      const account = createAccountFromFormValues(accountFormValues);
      await sendAccountInput(jobData?.details[0]?.id, account);

      history.push("/send-money");
    }
    catch (error) {
      // Todo: Replace this console.error with ui message.
      // console.error("[Account handleSubmit]: ", error);
    }

    setDisableSubmit(true);
  };

  return (
    <Row className="justify-content-md-center">
      <Col lg="6">
        <JoinAccountForm disableSubmit={ disableSubmit } onSubmit={ submitAccountData } />
      </Col>
    </Row>
  );
}

JoinAccount.propTypes = {
};

export default JoinAccount;
