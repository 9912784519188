// @dependecies
import React from "react";
// @assets
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
// @styles
import "./AccordionCollapseButton.scss";
// @components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @modules
import PropTypes from "prop-types";

function AccordionCollapseButton({
  className,
  ...props
}) {
  return (
    <button className={ `accordion-collapse-button ${className}` } { ...props }>
      <FontAwesomeIcon icon={ faChevronDown } />
    </button>
  );
}

AccordionCollapseButton.propTypes = {
  className: PropTypes.string,
};

export default AccordionCollapseButton;
