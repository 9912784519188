// @styles
import "./CloseButton.scss";
// @modules
import React from "react";

export default function CloseButton({
  className = "",
  ...props
}) {
  return (
    <button className={ `crediframework-close-button ${className}` } { ...props }>
      x
    </button >
  );
}
