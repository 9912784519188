import React from "react";
import style from "./DeviderOrange.module.scss";

function DeviderOrange() {
  return (
    <div className={ style.notificationOrangeDevider } />
  );
}

export { DeviderOrange };
