// @modules
import React from "react";
import { useHistory } from "react-router-dom";

// @styles
import styles from "./TipsBox.module.scss";

// @components
import Button from "@crediverso/crediframework/src/components/button";

// @assets
import { IconTip } from "../../assets";

function TipsBox({ tipDescription }) {
  const history = useHistory();

  const handleOnclick = () => {
    history.push("credit_checks/dashboard");
  };
  return (
    <div className={ styles.notificationTipBox }>
      <div className={ styles.iconContainer }>
        <IconTip />
      </div>
      <div className={ styles.descriptionContainer }>
        <h6 className={ styles.tipDescriptionText }>
          <span className={ styles.tipTitleText }>Tip: </span>
          {tipDescription}
        </h6>
      </div>
      <div className={ styles.buttonContainer }>
        <Button
          className={ styles.button }
          color="orange"
          id="cch_notiification_tips"
          onClick={ handleOnclick }
        >
          View my credit report
        </Button>
      </div>
    </div>
  );
}

export { TipsBox };
