// @modules
import React, { useState } from "react";
import Select from "../select";
import Label from "react-bootstrap/FormLabel";
import currencyList from "./currency";



const styles = {
  control: base => ({
    ...base,
    height: "calc(1.5em + 1rem + 2px)",
    fontSize: "1.25rem",
    lineHeight: "1.5",
    borderRadius: "0.3rem",
  }),
};


export default function SelectCurrency({ filter, ...props }) {
  const currency = filter ? currencyList.filter(currency => currency.value === filter.toLowerCase()) : currencyList;

  return (
    <Select
      options={ currency }
      styles={ styles }
      value={ props.val }
      { ...props }
    />
  );
}
