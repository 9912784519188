import { useState } from "react";
import styles from "./HeaderMyProfile.module.scss";
import { useTranslation } from "react-i18next";
import { useCrediverso } from "../../../../provider";
import { getWordPressBaseUrl } from "../../../../utils/urlUtils";
import LoadingUser from "./LoadingUser";
import UserLoaded from "./UserLoaded"

const HeaderMyProfile = (props) => {
  const { handleLogOut, crediversoState } = useCrediverso();
  const { t } = useTranslation();
  const [activeProfile, setActiveProfile] = useState(false);

  const onLogOut = () => {
    handleLogOut();
    const path = window.location.pathname.split("/")
    const currentPath = `${getWordPressBaseUrl()}log-out/?raas=/${path[1]}`;
    window.location.replace(currentPath);
  };

  return (
    <div
      className={styles.dropdown}
      onMouseLeave={() => setActiveProfile(false)}
      onMouseOver={() => setActiveProfile(true)}
    >
      <div className={styles.myProfile}>{t("header.myprofile")}</div>
      {activeProfile && (
        <div
          className={styles.dropdownInner}
          onMouseLeave={() => setActiveProfile(false)}
        >
          {crediversoState.isUserLoading
            ? <LoadingUser />
            : <UserLoaded />
          }
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className={styles.dropdownItem} onClick={onLogOut}>
            <span>{t("header.logout")}</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default HeaderMyProfile;
