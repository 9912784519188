// @styles
import "./InputPhone.scss";
// @modules
import React, { useState, useEffect } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import FormControl from "react-bootstrap/FormControl";
import PropTypes from "prop-types";
import countriesList from "../../utils/lists/countries";
import { mask as masker, unMask } from "remask";

const InputPhone = ({
  initialCountry = "us",
  label = null,
  name = null,
  ...props
}) => {
  const [country, setCountry] = useState(initialCountry);
  const [dataCountry, setDataCountry] = useState(
    countriesList.find(c => c.value === initialCountry),
  );

  useEffect(() => {
    setCountry(initialCountry);
    setDataCountry(countriesList.find(c => c.value === initialCountry));
  }, [initialCountry]);

  const handleSelect = e => {
    setCountry(e);
    setDataCountry(countriesList.find(c => c.value === e));
  };

  const handleChangePhone = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = masker(originalValue, countriesMask[country]);
    setValue(maskedValue);
  };

  const countriesMask = {
    mx: ["(999)-999-9999"],
    us: ["(999)-999-9999"],
    sv: ["9999-9999"],
    hn: ["9999-9999"],
    gt: ["9999-9999"],
    ni: ["9999-9999"],
  };

  const [value, setValue] = useState("");

  const optionsJSX = countriesList.map(option => (
    <Dropdown.Item
      eventKey={ option.value }
      href="#"
      key={ option.id }
      value={ option.value }
    >
      {option.name} {option.phoneCode}
    </Dropdown.Item>
  ));
  return (
    <InputGroup className="mb-3">
      <input name="phoneCountryCode" type="hidden" value={ country } />
      <DropdownButton
        as={ InputGroup.Prepend }
        id="input-group-dropdown-phone-country-code"
        onSelect={ handleSelect }
        title={
          country.toUpperCase() +
          " (" +
          dataCountry.phoneCode.toUpperCase() +
          ")"
        }
        variant="outline-secondary"
      >
        {optionsJSX}
      </DropdownButton>
      <FormControl
        aria-describedby="basic-addon1"
        name={ name }
        onChange={ handleChangePhone }
        value={ value }
      />
    </InputGroup>
  );
};

InputPhone.propTypes = {
  initialCountry: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
};

export default InputPhone;
