// @modules
import React, {
  //  useCallback, 
  useEffect} from "react";
import { useCrediverso } from "../../../../provider";

// @components
import SearchBar from "../HeaderSearchBar/SearchBar";
import HeaderLang from "../HeaderLang/HeaderLang";
// import RegisterButtons from "../RegisterButtons";

// @styles
import styles from "./MenuRight.module.scss";

// @assets
import { SeparatorIcon } from "../../../../assets";
// import HeaderMyProfile from "../HeaderMyProfile/HeaderMyProfile";
import Resources from "../Resources";

const MenuRight = () => {
  const { crediversoState } = useCrediverso();

  useEffect(() => {}, [crediversoState.isUserLoading, crediversoState.user]);

  // const renderProfile = useCallback(() => {
  //   const isUserLoggedIn = !!crediversoState.user.id;
  //   const isUserLoading = crediversoState.isUserLoading;

  //   if (isUserLoading) {
  //     return (
  //       <HeaderMyProfile />
  //     );
  //   }
  //   else if (isUserLoggedIn && !isUserLoading)
  //     return <HeaderMyProfile />;

  //   return <RegisterButtons />;
  // }, [crediversoState.isUserLoading, crediversoState.user.id]);

  return (
    <div className={ styles.container }>
      <SearchBar />
      {/* <div className={ styles.profile }>{renderProfile()}</div> */}
      <Resources />
      {/* <img alt="" src={ SeparatorIcon } /> */}
      <HeaderLang />
    </div>
  );
};

export default MenuRight;
