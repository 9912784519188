// @styles
import "./CodeConfirmation.scss";
// @components
import { Input, onSubmitHelper } from "@crediverso/crediframework";
import Button from "@crediverso/crediframework/src/components/button";
import InformationBox from "./InformationBox";
import Spinner from "react-bootstrap/Spinner";
// @modules
import { useState } from "react";

const idInputNumber = Array.from({ length: 6 }, (_, i) => `digit-${i}`);

function Devider() {
  return (
    <div className="devider_code_confirmation" />
  );
}

function Container(props) {
  return (
    <div style={ {
      marginRight: "10px",
      marginTop: "20px",
    } }>
      {props.children}
    </div>
  );
}

const onValidateNumber = object => {
  const number = object.target.value;

  if (number.length > 0)
    object.target.value = number[number.length - 1];

};

export default function CodeConfirmation({ onSubmit, resendCode, showMobileNumber }) {
  const [sendingCode, setSendingCode] = useState(false);

  const onSendCode = async event => {
    setSendingCode(true);
    const formValues = onSubmitHelper(event);
    const code = Object.values(formValues).join("");

    const bodyCode = { code };

    await onSubmit(bodyCode);
  };

  return (
    <form className="crediframework-modal-error" onSubmit={ onSendCode }>
      <div className="code_confirmation_wise_title_container">
        <h6 className="code_confirmation_wise_title_text">Enter 6 Digit Code</h6>
      </div>
      <InformationBox label="We send it to" />
      <div className="code_confirmation_container">
        {
          idInputNumber.map((id, index) => (
            <Container key={ id }>
              <Input name={ index.toString() } onInput={ onValidateNumber } type="number" />
            </Container>
          ))
        }
      </div>

      <div className="link_account_confirmation_container">
        <h6>Didn't Receive Code? <span className="crediframework-link-button" onClick={ resendCode }>Resend Code</span> or <span className="crediframework-link-button" onClick={ showMobileNumber }>Change Number</span></h6>
      </div>

      <Devider />

      <Button
        className="step_card__button-next-step my-5"
        color="orange"
        disabled={ sendingCode }
        type="submit"
      >
        { sendingCode ?
          <Spinner animation="border" variant="light" /> :
          "Submit Code"
        }
      </Button>
    </form>
  );
}
