// @styles
import "./CodeConfirmation.scss";
// @components
import Input from "../input";
import Button from "../button";
import InformationBox from "./InformationBox";
import Spinner from "react-bootstrap/Spinner";
// @modules
import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { onSubmitHelper } from "../../utils/form";

const idInputNumber = Array.from({ length: 6 }, (_, i) => `digit-${i}`);

function Devider() {
  return (
    <div className="devider_code_confirmation" />
  );
}

function Container(props) {
  return (
    <div style={ {
      marginRight: "10px",
      marginTop: "20px",
    } }>
      {props.children}
    </div>
  );
}

const onValidateNumber = object => {
  const number = object.target.value;

  if (number.length > 0)
    object.target.value = number[number.length - 1];

};

function CodeConfirmation({ onSubmit, resendCode, showMobileNumber }) {
  const [sendingCode, setSendingCode] = useState(false);
  const [translateText] = useTranslation("translations");

  const onSendCode = async event => {
    setSendingCode(true);
    const formValues = onSubmitHelper(event);

    const bodyCode = {
      code: Object.values(formValues).join(""),
    };

    await onSubmit(bodyCode);
  };
  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();
  const input5 = useRef();
  const input6 = useRef();

  const handleChangeRestrictedWithAutoFocus = event => {
    onValidateNumber(event);

    if (
      document.activeElement === input1.current &&
      input1.current.value.length === 1
    )
      input2.current.focus();

    if (
      document.activeElement === input2.current &&
      input2.current.value.length === 1
    )
      input3.current.focus();

    if (
      document.activeElement === input3.current &&
      input3.current.value.length === 1
    )
      input4?.current?.focus();


    if (
      document.activeElement === input4.current &&
      input4.current.value.length === 1
    )
      input5?.current?.focus();


    if (
      document.activeElement === input5.current &&
      input5.current.value.length === 1
    )
      input6?.current?.focus();
  };

  useEffect(() => {
    input1.current.focus();
  }, []);


  const inputRef = index => {
    switch (index) {
      case 0:
        return input1;
        break;
      case 1:
        return input2;
        break;
      case 2:
        return input3;
        break;
      case 3:
        return input4;
        break;
      case 4:
        return input5;
        break;
      case 5:
        return input6;
        break;
      default:
        break;
    }
  };
  return (
    <form className="crediframework-modal-error" onSubmit={ onSendCode }>
      <div className="code_confirmation_wise_title_container">
        <h6 className="code_confirmation_wise_title_text">Enter 6 Digit Code</h6>
      </div>
      <InformationBox label={ translateText("message.codeMessageSMS") } />
      <div className="code_confirmation_container">
        {
          idInputNumber.map((id, index) => (
            <Container key={ index.toString() }>
              <Input key={ id } name={ index.toString() } onInput={ handleChangeRestrictedWithAutoFocus } ref={ inputRef(index) }
                type="number" />
            </Container>
          ))
        }
      </div>

      <Devider />

      <Button
        className="step_card__button-next-step my-5"
        color="orange"
        disabled={ sendingCode }
        type="submit"
      >
        {sendingCode ?
          <Spinner animation="border" variant="light" /> :
          "Submit Code"
        }
      </Button>
    </form>
  );
}

export { CodeConfirmation };
