export const RATING = {
  EXCELLENT: "excellent",
  GOOD: "good",
  FAIR: "fair",
  POOR: "poor",
};

export const assessCreditScore = score => {
  if (score >= 780 && score <= 850)
    return RATING.EXCELLENT;
  else if (score >= 660 && score < 780)
    return RATING.GOOD;
  else if (score >= 600 && score < 660)
    return RATING.FAIR;
  else if (score >= 300 && score < 600)
    return RATING.POOR;
  return null;
};
