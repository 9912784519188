import React from "react";
import Select from "../select";
import GENDERS from "./genders";

export default function SelectGender({
  ...props
}) {
  return (
    <div className="crediframework-select-gender">
      <Select options={ GENDERS[props.lng] } { ...props } />
    </div>
  );
}
