const GENDERS = {
  "es" : [{
    id: "male",
    name: "Hombre",
    value: "male",
  },
  {
    id: "female",
    name: "Mujer",
    value: "female",
  }],
  "en" : [{
    id: "male",
    name: "Male",
    value: "male",
  },
  {
    id: "female",
    name: "Female",
    value: "female",
  }]}
;

export default GENDERS;
