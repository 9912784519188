// @components
import AccordionBootstrap from "react-bootstrap/Accordion";
import Card from "react-bootstrap/esm/Card";
import Collapse from "react-bootstrap/AccordionCollapse";
import Container from "react-bootstrap/Container";
import Toggle from "react-bootstrap/AccordionToggle";
// @modules
import React, { useEffect } from "react";
import PropTypes from "prop-types";


function Accordion({
  activeElementKey,
  elements,
  elementClassName,
}) {

  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 100,
    });
  }, [activeElementKey]);

  return (
    <AccordionBootstrap activeKey={ activeElementKey } defaultActiveKey={ activeElementKey }>
      {
        elements.map(element => (
          <Card className={ elementClassName } { ...(activeElementKey === element.key && { style: { backgroundColor: "white" } }) } id={ `step-${element.key}` } key={ element.key }>
            <Container>
              <Toggle as={ Card.Title } eventKey={ element.key }>
                {element.header}
              </Toggle>
              <Collapse eventKey={ element.key } id={ element.key }>
                {element.body}
              </Collapse>
            </Container>
          </Card>
        ))
      }
    </AccordionBootstrap>
  );
}

Accordion.propTypes = {
  activeElementKey: PropTypes.string.isRequired,
  elementClassName: PropTypes.string,
  elements: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(Accordion);
