// @modules
import React from "react";
import { useLang } from "../../../hooks";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
// @styles
import styles from "./HeaderLang.module.scss";

const HeaderLang = ({ short = false }) => {
  const { t } = useTranslation();
  const { switchLang } = useLang();

  return (
    <button
      className={ `${styles.toggle} ${short ? styles.short : ""}` }
      onClick={ switchLang }
    >
      {short ? t("header.lang-short") : t("header.lang")}
    </button>
  );
};

HeaderLang.propTypes = {
  short: PropTypes.bool,
};

export default HeaderLang;
