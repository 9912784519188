// @styles
import "./InputNumber.scss";
// @modules
import React, { useState } from "react";
import Control from "react-bootstrap/FormControl";
import PropTypes from "prop-types";
import { mask as masker, unMask } from "remask";

const InputCardCvv = ({
  label = null,
  name = null,
  ...props
}) => {


  const handleChangeCard = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = masker(originalValue, ["999"]);
    setValue(maskedValue);
  };

  const [value, setValue] = useState("");

  return (
    <Control
      name={ name }
      onChange={ handleChangeCard }
      type="text"
      value={ value }
      { ...props }
    />
  );
};

InputCardCvv.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

export default InputCardCvv;
